import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const ActivityLogs = ({ logs }) => {
  const headers = ["Action","Date"];
  const columns = ["action", "created_at"];

  const renderRow = (log) => (
    <>
    <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {log.action}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {formatDate(log.created_at)}
        </Typography>
      </td>    
    </>
  );

  return (
    <div>
      <Typography variant="h6" color="blue-gray" className="mb-3">
        Activity Logs
      </Typography>
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={logs || []}
        renderRow={renderRow}
        sortableColumns={[0]}
        searchEnabled={true}
      />
    </div>
  );
};

ActivityLogs.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      activity: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ActivityLogs;
