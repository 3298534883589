import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Spinner,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchVehicleFunc } from "@/apis/maintenances";
const VehicleDetailsPageComponent = ({id}) => {
    const [vehicleDetails, setVehicleDetails] = useState();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });

    useEffect(() => {
        setAlert({ status: false, message: '', type: '' });
        setLoading(true);
        const fetchData = async () => {
          try {
            const token = localStorage.getItem('token');
            const data = await fetchVehicleFunc(token, id);
            if (data) {
                setVehicleDetails(data);
                console.log("Unable: ",data)
            } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch trips.",
                    type: 'error',
                });
            }
          } catch (error) {
            sendSentryAlert(error);
            setAlert({
              status: true,
              message: 'We are having trouble fetching trips.',
              type: 'error',
            });
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, [id]);

    if (loading) {

    return <Spinner />;

    }

    return (
        <div className="mb-12 flex flex-col md:flex-row justify-between gap-6">
            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <div className="flex flex-col md:flex-row justify-between w-full ">
                <Card className="w-full p-5 m-5 bg-[#F5F7F8]">
                    <Typography variant="h6" color="blue-gray">
                        Default Driver: {vehicleDetails?.default_driver?.username || '-'}
                    </Typography>
                    <Typography variant="h6"color="blue-gray" >
                        Default Route: {`${vehicleDetails?.start_point || ''}${vehicleDetails?.start_point ? ' to ' : ' - '} ${vehicleDetails?.end_point || ""}` || '-'}
                    </Typography>
                    <Typography variant="h6" color="blue-gray">
                        Current Driver: {vehicleDetails?.currentDriver || '-'}
                    </Typography>
                    <Typography variant="h6" color="blue-gray">
                        Ownership: {vehicleDetails?.ownership  || '-'}
                    </Typography>
                    <Typography variant="h6" color="blue-gray">
                        Fleet Number: {vehicleDetails?.fleet_number  || '-'}
                    </Typography>               
                </Card>
                <Card className="w-full p-5 m-5 bg-[#F5F7F8]">
                    <CardBody>
                        <Typography variant="h6" color="blue-gray">
                            Current mileage: {vehicleDetails?.current_mileage  || '-'}
                        </Typography>
                        <Typography variant="h6" color="blue-gray">
                            Next Service mileage: {vehicleDetails?.service_mileage  || '-'}
                        </Typography>
                        <Typography variant="h6" color="blue-gray">
                            Insurance expiry: {vehicleDetails?.insurance_expiry  || '-'}
                        </Typography>
                        <Typography variant="h6" color="blue-gray">
                            Music Insurance expiry:  {vehicleDetails?.music_copyright_expiry  || '-'}
                        </Typography>
                    </CardBody>
                </Card>
            </div>
            
        </div>
    );
};

export default VehicleDetailsPageComponent;
