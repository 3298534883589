import React from 'react';
import { Typography } from '@material-tailwind/react';

const BookingsStatisticsComponent = ({ data }) => {
  const {
    bookingsToday,
    bookingsYesterday,
    bookingsLastWeek,
    bookingsThisMonth,
    bookingStatistics
  } = data;

  // Helper function to get the color based on the difference
  const getComparisonColor = (difference) => {
    try{
        if (difference > 0) return 'text-green-500';
        if (difference < 0) return 'text-red-500';
        return 'text-gray-300';
    }catch(e){
        return false;
    }   
  };

  return (
    <div className="mb-12 flex flex-col md:flex-row justify-between gap-6">
      {/* Statistics for bookings */}
      <div className="w-full md:w-3/4 text-white p-4 m-4 bg-gray-900 border-2 border-gray-800 shadow-lg rounded-lg">
        <div className="flex flex-col md:flex-row justify-between">
          {/* Today's bookings */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Today's Bookings 
            </Typography>
            <Typography variant="lead" color="white">
              {bookingsToday || 0}
            </Typography>
          </div>
          {/* Yesterday's bookings */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Yesterday's bookings
            </Typography>
            <Typography variant="lead" color="white">
              {bookingsYesterday?.bookingsYesterday || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(bookingsYesterday?.bookingsYesterday  - bookingsYesterday?.bookingsLastWeek ) || ''}
            >
              { bookingsYesterday?.bookingsYesterday  - bookingsYesterday?.bookingsLastWeek  > 0
                ? `+${bookingsYesterday?.bookingsYesterday  - bookingsYesterday?.bookingsLastWeek } more than same day last week`
                : bookingsYesterday?.bookingsYesterday  - bookingsYesterday?.bookingsLastWeek  < 0
                ? `${bookingsYesterday?.bookingsLastWeek  - bookingsYesterday?.bookingsYesterday } less than same day last week`
                : `Same as previous day`}
            </Typography>
          </div>
          {/* Last Week's bookings */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Last week's bookings
            </Typography>
            <Typography variant="lead" color="white">
              {bookingsLastWeek?.bookingsCurrentWeek || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(bookingsLastWeek?.bookingsCurrentWeek  - bookingsLastWeek?.bookingsLastWeek) || ''}
            >
              {bookingsLastWeek?.bookingsCurrentWeek  - bookingsLastWeek?.bookingsLastWeek > 0
                ? `+${bookingsLastWeek?.bookingsCurrentWeek  - bookingsLastWeek?.bookingsLastWeek} more than same day previous week`
                : bookingsLastWeek?.bookingsCurrentWeek  - bookingsLastWeek?.bookingsLastWeek < 0
                ? `${bookingsLastWeek?.bookingsLastWeek  - bookingsLastWeek?.bookingsCurrentWeek } less than same day previous week`
                : `Same as previous week`}
            </Typography>
          </div>
          {/* Last Month's bookings */}
          <div className="p-2">
            <Typography variant="small" color="gray-300">
              Last month's bookings
            </Typography>
            <Typography variant="lead" color="white">
              {bookingsThisMonth?.bookingsCurrentMonth || 0}
            </Typography>
            <Typography variant="lead" color="white">
              {bookingsThisMonth?.bookingsCurrentMonth?.toLocaleString() || 0}
            </Typography>
            <Typography
              variant="small"
              className={getComparisonColor(bookingsThisMonth?.bookingsCurrentMonth  - bookingsLastWeek) || ''}
            >
              {bookingsThisMonth?.bookingsCurrentMonth  - bookingsThisMonth?.bookingsLastMonth  > 0
                ? `+${bookingsThisMonth?.bookingsCurrentMonth  - bookingsThisMonth?.bookingsLastMonth } more than previous month`
                : bookingsThisMonth?.bookingsCurrentMonth  - bookingsThisMonth?.bookingsLastMonth  < 0
                ? `${bookingsThisMonth?.bookingsLastMonth  - bookingsThisMonth?.bookingsCurrentMonth } less than previous month`
                : `Same as previous month`}
            </Typography>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/4 lg:w-1/4 p-4 m-4 bg-gray-900 text-white border-2 border-gray-800 shadow-lg rounded-lg">
        <div className="w-full flex justify-between mb-2">
          <Typography variant="small" color="gray-300">
            Completed
          </Typography>
          <Typography variant="lead" color="white">
            {bookingStatistics?.completedBookings || '-' }
          </Typography>
        </div>
        <div className="w-full flex justify-between mb-2">
          <Typography variant="small" color="gray-300">
            No Shows
          </Typography>
          <Typography variant="lead" color="white">
            {bookingStatistics?.noShowBookings || '-'}
          </Typography>
        </div>
        <div className="w-full flex justify-between">
          <Typography variant="small" color="gray-300">
            Canceled
          </Typography>
          <Typography variant="lead" color="white">
            {bookingStatistics?.canceledBookings || '-'}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BookingsStatisticsComponent;
