import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Button,
  Input
} from "@material-tailwind/react";
import { UserCircleIcon , CloudArrowDownIcon, MagnifyingGlassIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

function BillingCard({ name, details, organization, bookingBreakdown }) {
  return (
    <Card
      shadow={false}
      className="rounded-lg border border-gray-300 p-4"
    >
      <div className="mb-4 flex items-start justify-between">
        <div className="flex items-center gap-3">
          <div className="border border-gray-200 p-2.5 rounded-lg">
            <UserCircleIcon className="h-6 w-6 text-gray-900" />
          </div>
          <div>
            <Typography variant="small" color="blue-gray" className="mb-1 font-bold">
              {name}
            </Typography>
            <Typography
              className="!text-gray-600 text-xs font-normal"
            >
              {organization}
            </Typography>
          </div>
        </div>
        <div>
          {bookingBreakdown && (
            <div>
              {Object.keys(bookingBreakdown).map((label) => (
                <div key={label} className="flex gap-1">
                  <Typography className="text-xs !font-medium !text-gray-600">
                    {label}:
                  </Typography>
                  <Typography
                    className="text-xs !font-bold"
                    color="blue-gray"
                  >
                    {bookingBreakdown[label]}
                  </Typography>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center justify-between">
          <Button
            size="sm"
            variant="text"
            color="red"
            className="flex items-center gap-2"
          >
            <CloudArrowDownIcon className="h-4 w-4 text-red-500" />
            <Typography className="!font-semibold text-xs text-red-500 md:block hidden">
              Download
            </Typography>
          </Button>
        </div>
      </div>
      <div>
        {details && (
          <div>
            {Object.keys(details).map((label) => (
              <div key={label} className="flex gap-1">
                <Typography className="mb-1 text-xs !font-medium !text-gray-600">
                  {label}:
                </Typography>
                <Typography
                  className="text-xs !font-bold"
                  color="blue-gray"
                >
                  {details[label]}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
}

const billingCardData = [
  {
    name: "Burrito Vikings",
    organization: "Organization One",
    details: {
      "Email Address": "emma@mail.com",
      "Phone Number": "FRB1235476",
    },
    bookingBreakdown: {
      "Distance Traveled(km)": 45.98,
      "Amount Billable (Ksh)": 6467.88,
      "Bookings": 43,
      "Prominent Route": "Some Route",
    }
  },
  {
    name: "Stone Tech Zone",
    organization: "Company",
    details: {
      "Email Address": "marcel@mail.com",
      "Phone Number": "FRB1235476",
    },
    bookingBreakdown: {
      "Distance Traveled(km)": 45.98,
      "Amount Billable (Ksh)": 6467.88,
      "Bookings": 43,
      "Prominent Route": "Some Route",
    }
  },
  {
    name: "Fiber Notion",
    organization: "Company",
    details: {
      "Email Address": "misha@mail.com",
      "Phone Number": "FRB1235476",
    },
    bookingBreakdown: {
      "Distance Traveled(km)": 45.98,
      "Amount Billable (Ksh)": 6467.88,
      "Bookings": 43,
      "Prominent Route": "Some Route",
    }
  },
  // More data here if necessary
];

function PassengerInvoicesComponent() {
  const [activePage, setActivePage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(""); // State to track search input
  const itemsPerPage = 2;

  const totalPages = Math.ceil(billingCardData.length / itemsPerPage);

  const handleNext = () => {
    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const handlePrev = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  // Filtering the data based on the search query
  const filteredData = billingCardData.filter((item) => {
    const lowerQuery = searchQuery.toLowerCase();

    // Check in name, organization, details, and bookingBreakdown fields
    return (
      item.name.toLowerCase().includes(lowerQuery) ||
      item.organization.toLowerCase().includes(lowerQuery) ||
      Object.values(item.details).some((detail) =>
        detail.toLowerCase().includes(lowerQuery)
      ) ||
      Object.values(item.bookingBreakdown).some((value) =>
        value.toString().toLowerCase().includes(lowerQuery)
      )
    );
  });

  // Paginate the filtered data
  const currentData = filteredData.slice(
    (activePage - 1) * itemsPerPage,
    activePage * itemsPerPage
  );

  return (
    <section className="!mx-auto px-2 py-5 w-full">
      <Card shadow={false}>
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex gap-2 flex-col md:flex-row items-start justify-between"
        >
          <div className="w-full mb-2">
            <Typography className="!font-bold" color="blue-gray">
              Passenger Billing Information
            </Typography>
            <Typography
              className="mt-1 !font-normal !text-gray-600"
              variant="small"
            >
              Passenger invoices.
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full md:w-72">
              <Input
                size="lg"
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
              />
            </div>
            <Button
              variant="outlined"
              className="flex items-center gap-2"
            >
              24h
              <ChevronDownIcon strokeWidth={3} className="w-3 h-3" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="flex flex-col gap-4 !p-4">
          {currentData.map((props, key) => (
            <BillingCard key={key} {...props} />
          ))}

          {/* Show message if no data matches search */}
          {currentData.length === 0 && (
            <Typography className="text-center text-gray-600">
              No results found.
            </Typography>
          )}
        </CardBody>
      </Card>

      {/* Pagination Section */}
      <section className="py-4 px-4">
        <div className="flex items-center gap-8 justify-between">
          <Typography variant="small" className="font-bold text-gray-600">
            Page <strong className="text-gray-900">{activePage}</strong> of{" "}
            <strong className="text-gray-900">{Math.ceil(filteredData.length / itemsPerPage)}</strong>
          </Typography>
          <div className="flex gap-4 items-center">
            <Button
              size="sm"
              variant="outlined"
              onClick={handlePrev}
              disabled={activePage === 1}
              className="flex gap-1 items-center border-gray-300"
            >
              <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
              prev
            </Button>
            <Button
              size="sm"
              variant="outlined"
              onClick={handleNext}
              disabled={activePage === totalPages || currentData.length === 0}
              className="flex gap-1 items-center border-gray-300"
            >
              next
              <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
            </Button>
          </div>
        </div>
      </section>
    </section>
  );
}

export default PassengerInvoicesComponent;
