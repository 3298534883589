import React, { useState, useEffect } from "react";
import {DispatchTableComponent} from "@/widgets/components/reusableComponents/tables/dispatchTableComponent";
import {
  Spinner
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchAllUnassignedBookings, fetchUnassignedResources } from "@/apis/trips"


export const UnassignedDispatchComponent = () => {
    const headers = ["Passenger Name", "Passenger Email", "Date" ];
    const columns = ["passengerName","passengerEmail", "tripDate"];
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const [pool, setPool] = useState([]);
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        setAlert({ status: false, message: '', type: '' });
        setLoading(true);
        const fetchPoolData = async () => {
              try {
                const token = localStorage.getItem('token');
                const records = await fetchAllUnassignedBookings(token);
                if (records) {
                  setPool(records);
                } else {
                  setAlert({
                    status: true,
                    message: "Unable to fetch records.",
                    type: 'error',
                  });
                }
              } catch (error) {
                sendSentryAlert(error);
                setAlert({
                  status: true,
                  message: 'We are having trouble fetching records.',
                  type: 'error',
                });
              } finally {
                setLoading(false);
              }
        };
    
        const fetchOtherData = async () => {
          try {
            const token = localStorage.getItem('token');
            const records = await fetchUnassignedResources(token);
            if (records) {
              setVehicles(records.unallocatedVehicles);
              setDrivers(records.unallocatedDrivers);
            } else {
              setAlert({
                status: true,
                message: "Unable to fetch records.",
                type: 'error',
              });
            }
          } catch (error) {
            sendSentryAlert(error);
            setAlert({
              status: true,
              message: 'We are having trouble fetching records.',
              type: 'error',
            });
          } finally {
            setLoading(false);
          } 
        };
    
        fetchOtherData()
        fetchPoolData();
      }, []);

    if(loading){
        return <Spinner />
    }
    return (
        <>
            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <DispatchTableComponent
                headers={headers}
                columns={columns}
                data={pool || []}
                sortableColumns={[0,1,2,3,4,5,6,7,8]}
                titleColumnIndex={0}
                searchEnabled={true}
                titleClickable={false}
                settingsColumn={true}
                hideCreateButton={true}
                drivers={drivers}
                vehicles={vehicles}
            />
        </>
    );
};

export default UnassignedDispatchComponent;
