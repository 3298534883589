import React, { useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import { fetchChargeChartData } from "@/apis/maintenances";


const FuelChargeChartComponent = ({  }) => {
  const headers = [ "Fuel Cost","Status","Active Period", "Date Created"];
  const columns = ["fuel_cost", "status", "active_period", "created_at"];

  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchChargeChartData(token);
        const updatedCharges = data?.charges?.map(charge => ({
          ...charge,
          status: charge.is_active ? "Active" : "Inactive"
        }));
        setCharges(updatedCharges);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching charges.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getVehicles();
  }, [])

  if(loading){
      return <Spinner />
  }

  return (
        <>
            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <ReusableTableComponent
                headers={headers}
                columns={columns}
                data={charges || []}
                sortableColumns={[0, 3]}
                titleColumnIndex={0}
                searchEnabled={true}
                titleClickable={false}
                settingsColumn={false}
                createEntityLink="/dashboard/fuel-charges/create"
            />
        </>
    )
};

export default FuelChargeChartComponent;
