import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import UsersTable from "@/widgets/components/pagecomponents/users/userTable";
import { fetchUsers, updateUserStatus } from "@/apis/dash";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';

export function Users() {
  const [users, setUsers] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });

    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchUsers(token);
        if (data) {
          setUsers(data.users);
          setUserStats([
            { label: 'Total Users', value: data.statistics.totalUsers },
            { label: 'Admins Users', value: data.statistics.TotalAdmins },
            { label: 'Dispatch Users', value: data.statistics.TotalDispatch },
            { label: 'Accounts Users', value: data.statistics.TotalAccounts },
          ]);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "We are having trouble fetching user data. Try again.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching user data. Try again.',
          type: 'error',
        });
      }
    };

    fetchData();
  }, []);

  const handleSettingsClick = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await updateUserStatus(token, userId);
      if (response) {
        return true;    
      }
      return false;
    } catch (error) {
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: `Failed to update user status. Please try again.`,
        type: 'error',
      });
    }
  };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6">
          <Typography variant="h6" color="white">
            System Users
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
          {loading ? (
            <Typography>
              <Spinner />
            </Typography>
          ) : (
            alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : (
              <UsersTable users={users} statistics={userStats} handleSettingsClick={handleSettingsClick} />
            )
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Users;
