import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Button, Typography, Spinner, Alert } from '@material-tailwind/react';
import authApi from '@/apis/auth';
import GeneralAlert from '@/widgets/alerts/general';
import sendSentryAlert from '@/utils/sentryUtil';
import { useAuth } from '@/context/auth';

export function SignIn() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleInput = (e) => {
    setAlert({ status: false, message: '', type: '' });
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const loginData = await authApi.login(formData);
      if (loginData && loginData.token) {
        login(loginData.token, loginData.userDetails);         
        navigate('/dashboard/home');       
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: loginData.details || 'Unable to log you in at this time. Contact administrator',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({ status: true, message: 'System unable to handle login attempt. Contact your administrator.', type: 'error' });
    }
  };

  return (
    <section className="m-8 flex gap-4">
      <div className="w-full lg:w-3/5 mt-24">
        <div className="text-center">
          <Typography variant="h2" className="font-bold mb-4">Welcome Back</Typography>
          {loadData ? (
            <div className="w-1/2 mx-auto">
              <Alert color="green"> 
                <Typography variant="paragraph" color="white" className="text-lg font-normal">
                  We are setting you up. This will only take a few moments...
                  <Spinner /> 
                </Typography>
              </Alert> 
            </div>
          ) : (
            alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : (
              <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">Enter your email and password to Sign In.</Typography>
            )
          ) }

        </div>

        <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2" onSubmit={handleSignIn}>
          <div className="mb-1 flex flex-col gap-6">
            <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">Email</Typography>
            <Input
              size="lg"
              name="email"
              type="email"
              onChange={handleInput}
              required
              placeholder="john@doe.com"
              className="!border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{ className: "before:content-none after:content-none" }}
            />
            <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">Password</Typography>
            <Input
              type="password"
              name="password"
              onChange={handleInput}
              required
              size="lg"
              placeholder="********"
              className="!border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{ className: "before:content-none after:content-none" }}
            />
          </div>

          {loading ? (
            <Button className="mt-6" color="green" fullWidth variant="outlined"><Spinner /></Button>
          ) : (
            <Button type="submit" color="green" className="mt-6" fullWidth>Sign In</Button>
          )}

          <div className="flex items-center justify-between gap-2 mt-6">
            <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
              <Link to="/auth/reset-password" className="text-gray-900 ml-1">reset password</Link>
            </Typography>
            {/* <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
              <Link to="/auth/activate-account" className="text-gray-900 ml-1">activate account</Link>
            </Typography> */}
          </div>
        </form>
      </div>

      <div className="w-full lg:w-2/5 flex justify-center items-center">
        <img src="/img/SidewaysLogo.jpg" className="rounded-3xl" alt="Pattern" />
      </div>

    </section>
  );
}