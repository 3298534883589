import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateVehicleFormComponent from '/src/widgets/components/pagecomponents/maintenances/CreateVehiclesFormComponents';
import GeneralAlert from '@/widgets/alerts/general';
import { createVehicles, fetchAvailableDriversData } from "@/apis/maintenances";
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('token');

export function CreateVehiclePageComponent() {
    const [vehicleData, setVehicleData] = useState({
        registration: '',
        capacity: '',
        fleet_number: '',
        driver_id: '',
        status: 'ACTIVE',
        ownership: 'OWNED',
        fuel_type: '',
        make: '',
        model: '',
        current_mileage: '',
        service_mileage: '',
        next_inspection: '',
        music_copyright_expiry: '',
        km_litre: '',
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '', type: '', });
    const navigate = useNavigate();
    const [drivers, setDrivers] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setVehicleData({ ...vehicleData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setVehicleData({ ...vehicleData, [name]: files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '', type: '' });

        const formData = new FormData();
        for (const key in vehicleData) {
            formData.append(key, vehicleData[key]);
        }

        try {
          console.log(vehicleData);
            const createVehicle = await createVehicles(token, vehicleData);
            if (createVehicle) {
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Vehicle created successfully.',
                    type: 'success'
                });
                setVehicleData({
                    registration: '',
                    capacity: '',
                    fleet_number: '',
                    driver_id: '',
                    status: 'ACTIVE',
                    ownership: 'OWNED',
                    fuel_type: '',
                    make: '',
                    model: '',
                    current_mileage: '',
                    service_mileage: '',
                    next_inspection: '',
                    music_copyright_expiry: '',
                    km_litre: '',
                });
            } else {
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Failed to create vehicle.',
                    type: 'error'
                });
            }
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.details || 'Failed to create vehicle.',
                type: 'error'
            });
        }
    };

    const handleSelectChange = (name, value) => {
        setVehicleData({ ...vehicleData, [name]: value });
    };

    useEffect(() => {
        async function fetchAvailableDrivers() {
            try {
                const data = await fetchAvailableDriversData(token);
                setDrivers(data);
            } catch (error) {
                setAlert({ status: true, message: 'Error fetching drivers.', type: 'error' });
            } finally {
                setLoading(false);
            }
        }

        fetchAvailableDrivers();
    }, []);

    return (
        <div className="mt-12 mb-8 flex flex-col gap-12">
            <Card>
                <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between">
                  <Typography variant="h6" color="white">
                    Create Vehicle
                  </Typography>
                  <div className="flex items-center">
                    <button onClick={() => navigate(`/dashboard/maintenances`)} className="text-white underline">
                      Back
                    </button>
                  </div>
                </CardHeader>
                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {alert.status ? (
                        <div className="w-1/2 mx-auto">
                            <GeneralAlert props={{ ...alert, setAlert }} />
                        </div>
                    ) : null}

                    <CreateVehicleFormComponent
                        formData={vehicleData}
                        loading={loading}
                        handleSelectChange={handleSelectChange}
                        handleInputChange={handleInputChange}
                        handleFileChange={handleFileChange} 
                        handleSubmit={handleSubmit}
                        drivers={drivers || []}
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default CreateVehiclePageComponent;
