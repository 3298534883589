import React, { useState, useEffect } from 'react';
import { Card, CardBody, Tabs, TabsHeader, Tab, Typography, Alert, Spinner } from "@material-tailwind/react";
import { HomeIcon, AdjustmentsVerticalIcon, Cog6ToothIcon, ArrowLeftIcon } from "@heroicons/react/24/solid";
import GeneralInformation from '@/widgets/segments/GeneralProfileInfo';
import SecuritySettings from '@/widgets/segments/SecuritySettings';
import ActivateDeactivate from '@/widgets/segments/ActivateDeactivate';
import ActivityLogs from '@/widgets/segments/UseractivityLogs';
import { fetchUserData }  from "@/apis/maintenances";
import { useParams, useNavigate } from 'react-router-dom';
import  { resetPassword } from "@/apis/profile";

export function UserPage() {

  const [activeTab, setActiveTab] = useState("general");
  const [userDetails, setUserDetails] = useState({});
    const [formData, setFormData] = useState({
      newPassword: '',
      confirmPassword: ''
    });

  
  const { id } = useParams();
  const navigate = useNavigate();
  const handleTabChange = (value) => {
    setActiveTab(value);
  };
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setAlert({ status: true, message: 'New password and confirm password do not match', type: 'error' });
      return;
    }
    setLoading(true);
    setAlert({ status: false, message: '', type: '' });
    const token = localStorage.getItem('token');
    const result = await resetPassword(token, formData.newPassword);
    if (result) {
      setAlert({ status: true, message: 'Password reset successful', type: 'success' });
      setLoading(false);
    } else {
      setAlert({ status: true, message: 'Password reset failed', type: 'error' });
      setLoading(false);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForgotPasswordRequest = async () => {
    const email = prompt('Please enter your email:');
    if (email) {
      setLoading(true);
      setAlert({ status: false, message: '', type: '' });
      const result = await requestPasswordReset(email);
      setLoading(false);
      if (result) {
        setAlert({ status: true, message: 'Password reset request successful', type: 'success' });
      } else {
        setAlert({ status: true, message: 'Password reset request failed', type: 'error' });
      }
    }
  };

  const handleRedirectBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const  token = localStorage.getItem('token');
        const response = await fetchUserData(token, id);
        console.log('response: ',response)
        setUserDetails(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setAlert({
          status:true,
          message: error.message || 'Failed to fetch user details.'
        });
        setLoading(false);
      } 
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="large" color="blue" />
      </div>
    );
  }

  return (
    <>
      {alert.status && (
        <div className="flex items-center justify-center h-screen">
          <Alert color="red">
            <Typography variant="subtitle1">{alert.error}</Typography>
          </Alert>
        </div>
      )}
        <Card className="mx-3 mt-16 mb-6 lg:mx-4 border border-blue-gray-100">
          <CardBody className="p-4">
            <div className="mb-10 flex justify-between">
              <div className="w-1/3">              
                <div>
                  <Typography variant="h5" color="blue-gray" className="mb-1">
                    {userDetails.username || '---' }
                  </Typography>
                  <Typography
                    variant="small"
                    className="font-normal text-blue-gray-600"
                  >
                    {userDetails.roles || '---'}
                  </Typography>
                </div>
              </div>
              <div className="w-2/3">
                <Tabs value={activeTab}>
                  <TabsHeader>
                    <Tab value="back" onClick={() => handleRedirectBack()} className="mx-2">
                      <ArrowLeftIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                      Back
                    </Tab>
                    <Tab value="general" onClick={() => handleTabChange("general")}  className="mx-2">
                      <HomeIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                      General
                    </Tab>
                    <Tab value="settings" onClick={() => handleTabChange("settings")}  className="mx-2">
                      <Cog6ToothIcon className="-mt-0.5 mr-2 inline-block h-5 w-5" />
                      Settings
                    </Tab>
                    <Tab value="logs" onClick={() => handleTabChange("logs")}  className="mx-2">
                      <AdjustmentsVerticalIcon className="-mt-1 mr-2 inline-block h-5 w-5" />
                      Logs
                    </Tab>
                  </TabsHeader>
                </Tabs>
              </div>
            </div>

            {activeTab === "general" && (
              <GeneralInformation userDetails={userDetails} />
            )}
            
            {activeTab === "settings" && (
              <SecuritySettings 
                  formData={formData}
                  onFormChange={handleFormChange}
                  onSubmit={handleSubmit}
                  onForgotPasswordRequest={handleForgotPasswordRequest}
                  loading={loading}
                  alert={alert}
                  setAlert={setAlert} 
              />
            )}

            {activeTab === "logs" && (
              <ActivityLogs logs ={userDetails.userLogs} />
            )}

          </CardBody>
        </Card>
    </>
  );
}

export default UserPage;
