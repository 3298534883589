import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchOrgBookings } from "@/apis/organisations"
const OrgBookings = ({ id }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const bookings = await fetchOrgBookings(token, id);
        if (bookings) {
          console.log('bookings: ', bookings)
          setBookings(bookings);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch bookings.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching bookings.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = [ "Status", "Passenger Name", "Passenger Email",, "Category", "Leg", "Trip Date"];
  const columns = [ "completionStatus", "passengerName", "passengerEmail", "tripCategory", "tripLeg", "tripDate"];

  const renderRow = (booking) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {booking.booking_id}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.status}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.pickup_time}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.pickup_address}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.drop_off_address}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.group}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.passengers}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {booking.departments}
        </Typography>
      </td>
    </>
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <>      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={bookings || []}
        renderRow={renderRow}
        sortableColumns={[0, 1, 2, ]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        hideCreateButton = {true}
      />
    </>
  );
};

OrgBookings.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OrgBookings;
