import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input,
  Tabs,
} from "@material-tailwind/react";

import { ChevronUpDownIcon, MagnifyingGlassIcon, CogIcon, UserPlusIcon, ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { UploadModal } from "@/widgets/modals/uploadBulk";
import {
  EyeIcon
} from "@heroicons/react/24/solid";

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;
  return date.toLocaleDateString('en-GB', options).replace(',', '');
};

export const ReusableTableComponent = ({
  headers,
  columns,
  data,
  statistics,
  sortableColumns,
  searchEnabled,
  titleClickable,
  titleClickableUrl,
  titleColumnIndex,
  settingsColumn,
  handleSettingsEditLink,
  createEntityLink,
  hideCreateButton,
  allowsBulkUpload,
  sampleFileData,
  onUploadFile,
  loading
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);

  const rowsPerPage = 10;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleOpenEdit = (record) => {
    if (handleSettingsEditLink) {
      navigate(`${handleSettingsEditLink}/${record.id}`);
    }
  };
  

  const downloadSampleFile = () => {
    const headers = Object.keys(sampleFileData[0]);
    const rows = sampleFileData.map(vehicle => headers.map(header => vehicle[header]).join(","));  
    const csvContent = "data:text/csv;charset=utf-8," + [headers.join(","), ...rows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    if (!sortConfig.key) {
      return data;
    }

    return data.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const openCreateNewEntity = () => {
    if (createEntityLink) {
      navigate(createEntityLink);
    }
  };

  const filteredData = sortedData().filter((row) =>
    Object.values(row).some(
      (value) =>
        typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = headers;
    const tableRows = [];

    paginatedData.forEach((row) => {
      const rowData = columns.map((col) => {
        const value = row[col];
        return typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T/)
          ? formatDate(value)
          : value;
      });
      tableRows.push(rowData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("table.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      paginatedData.map((row) => {
        const rowData = {};
        headers.forEach((header, index) => {
          let value = row[columns[index]];
          if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
            value = formatDate(value);
          }
          rowData[header] = value;
        });
        return rowData;
      })
    );
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "table.xlsx");
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-2 flex items-center justify-between gap-8">
          {statistics.length ? (
            <div>
              <div className="flex gap-4 mt-4">
                {statistics?.map((stat, index) => (
                  <Card key={index} className="p-4">
                    <Typography variant="small">{stat.label}</Typography>
                    <Typography variant="h4">{stat.value}</Typography>
                  </Card>
                ))}
              </div>
            </div>
          ): null}
          <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
            {!hideCreateButton && (
              <Button className="flex items-center gap-3" size="sm" onClick={openCreateNewEntity}>
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> New
              </Button>
            )}
            {allowsBulkUpload && (
              <>
                <Button className="flex items-center gap-3" size="sm" onClick={openModal}>
                  <ArrowUpTrayIcon strokeWidth={2} className="h-4 w-4" /> Upload Bulk
                </Button>
                <UploadModal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  onDownloadSample={downloadSampleFile}
                  onUploadFile={onUploadFile}
                  loading={loading}
                />
              </>
            )}
            <Button className="flex items-center gap-3" size="sm" onClick={exportToExcel}>
              Export to Excel
            </Button>
            <Button className="flex items-center gap-3" size="sm" onClick={exportToPDF}>
              Export to PDF
            </Button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
          <Tabs value="all" className="w-full">
            {/* Add your Tabs logic here if needed */}
          </Tabs>
          {searchEnabled && (
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                onChange={handleSearch}
              />
            </div>
          )}
        </div>
      </CardHeader>

      <CardBody className="overflow-x-auto px-0">
        <table className="mt-4 mx-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-bold leading-none opacity-70"
                  onClick={() => requestSort("index")}
                >
                  #
                </Typography>
              </th>
              {headers.map((header, index) => (
                <th
                  key={header}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={() => sortableColumns && requestSort(columns[index])}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center gap-2 font-bold leading-none opacity-70"
                  >
                    {header}{" "}
                    {sortConfig.key === columns[index] && (
                      <ChevronUpDownIcon
                        strokeWidth={2}
                        className={`h-4 w-4 transition-transform ${
                          sortConfig.direction === "desc" ? "rotate-180" : ""
                        }`}
                      />
                    )}
                  </Typography>
                </th>
              ))}
              {settingsColumn && (
                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-bold leading-none opacity-70"
                  >
                    Actions
                  </Typography>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((record, rowIndex) => (
              <tr key={record.id || rowIndex} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {rowIndex + 1 + (currentPage - 1) * rowsPerPage}
                  </Typography>
                </td>
                {columns.map((col, colIndex) => (
                  <td
                    key={`${record.id || rowIndex}-${colIndex}`}
                    className="p-4"
                    onClick={
                      titleClickable && titleColumnIndex === colIndex
                        ? () => navigate(`${titleClickableUrl}/${record.id}`)
                        : undefined
                    }
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className={`font-normal ${
                        titleClickable && titleColumnIndex === colIndex ? "cursor-pointer text-blue-500" : ""
                      }`}
                    >
                      {typeof record[col] === "string" && record[col].match(/^\d{4}-\d{2}-\d{2}T/)
                        ? formatDate(record[col])
                        : record[col]}
                    </Typography>
                  </td>
                ))}
                {settingsColumn && (
                  <td className="p-4">
                        <Button
                          onClick={() => handleOpenEdit(record)}
                        >
                          <EyeIcon className="h-5 w-5" />
                        </Button>  
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>

      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {currentPage} of {totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            color="blue-gray"
            size="sm"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

ReusableTableComponent.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  sortableColumns: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  titleClickable: PropTypes.bool,
  titleClickableUrl: PropTypes.string,
  titleColumnIndex: PropTypes.number,
  settingsColumn: PropTypes.bool,
  handleSettingsEditLink: PropTypes.string,
  createEntityLink: PropTypes.string,
  hideCreateButton: PropTypes.bool,
  allowsBulkUpload: PropTypes.bool,
  sampleFileData: PropTypes.arrayOf(PropTypes.object),
  onUploadFile: PropTypes.func,
  loading: PropTypes.bool,
};

ReusableTableComponent.defaultProps = {
  statistics: [],
  sortableColumns: false,
  searchEnabled: false,
  titleClickable: false,
  titleClickableUrl: "",
  titleColumnIndex: 0,
  settingsColumn: false,
  handleSettingsEditLink: "",
  createEntityLink: "",
  hideCreateButton: false,
  allowsBulkUpload: false,
  sampleFileData: [],
  onUploadFile: null,
  loading: false,
};
