import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import sendSentryAlert from "@/utils/sentryUtil";
import { fetchVehiclesData, uploadBulkVehicles } from "@/apis/maintenances";

const returnUpper = (passedString) => passedString.toUpperCase();

const VehicleComponent = () => {
  const headers = ["Vehicle Registration", "Fleet Number", "Vehicle Model", "Vehicle Make", "Capacity", "Ownership Category","Route Allocated", "Date Added"];
  const columns = ["registration", "fleet_number", "model", "make", "capacity", "ownership","route_code", "created_at"];

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    const getVehicles = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const data = await fetchVehiclesData(token);
        setVehicles(data);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching vehicles.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getVehicles();
  }, []);



  return (
    <ReusableTableComponent
      headers={headers}
      columns={columns}
      data={vehicles || []}
      sortableColumns={[0, 1, 2, 3, 4, 5, 6]}
      titleColumnIndex={0}
      searchEnabled={true}
      settingsColumn={false}
      createEntityLink="/dashboard/drivers/create"
      loading={loading}
      titleClickable={true}
      titleClickableUrl="/dashboard/vehicle"
    />
  );
};


export default VehicleComponent;
