import React, { useState } from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Spinner
} from "@material-tailwind/react";
import {
    ArrowPathIcon
} from "@heroicons/react/24/solid";

export function CreateStageFormComponent({
    formData,
    loading,
    handleInputChange,
    handleSubmit
}) {
    
    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Name
                        </Typography>
                        <Input
                            size="lg"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Stage'}
                        </Button>
                    </div>
                </div>               
            </form>
        </Card>
    );
}

export default CreateStageFormComponent;
