import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const OrganisationsTable = ({ organisations, statistics }) => {
  const headers = ["name",  "Staff count", "Agency Type","Status", "Date Created"];
  const columns = ["name", "staffcount","agency_type",'is_active',"date_created"]


  return (
    <ReusableTableComponent
      headers={headers}
      columns={columns}
      data={organisations}
      statistics={statistics}
      sortableColumns={[0,1,2, 3]}
      titleColumnIndex={0}
      searchEnabled={true}
      titleClickable={true}
      titleClickableUrl="/dashboard/organisations"
      settingsColumn={false}
      createEntityLink="/dashboard/organisations/create"
    />
  );
};

OrganisationsTable.propTypes = {
  organisations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      staffcount: PropTypes.number,
      subagencies: PropTypes.number,
      date_created: PropTypes.string,
      agency_type: PropTypes.string,
      departments: PropTypes.number
    })
  ).isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default OrganisationsTable;
