import React, { useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateOrgDepartmentFormComponent from "@/widgets/components/pagecomponents/organisations/createDepartment";
import { createOrgDepartmentFunc } from "@/apis/organisations";
import GeneralAlert from '@/widgets/alerts/general';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateOrgDepartment() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [departmentData, setDepartmentData] = useState({
        name: '',
        agencyId: id,
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDepartmentData({ ...departmentData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const createDepartment = await createOrgDepartmentFunc(token, departmentData);
            if(createDepartment){
                setLoading(false);
                setAlert({
                    status: true,
                    message: createDepartment.details,
                    type: 'success',
                });
                setDepartmentData({
                    name: '',
                    agencyId: id
                })
            }else{
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Failed to create department.',
                    type: 'error',
                });
               
            }
            
        } catch (error) {
            console.log(error)
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create department.',
                type: 'error',
            });
        }
    };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between ">
            <Typography variant="h6" color="white">
                Create Department
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/organisations/${id}`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>

        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">        
            { alert.status ? (
                <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
                </div>
            ) : null }
            <CreateOrgDepartmentFormComponent
                formData={departmentData}
                loading={loading}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                createEntityLink={`/dashboard/organisations/create-depatment/${id}`}
            />
        </CardBody>
        </Card>
        </div>
    );
}

export default CreateOrgDepartment;
