import React, { useState } from 'react';
import {
    Card,
    Input,
    Button,
    Typography,
    Spinner,
    Select,
    Option,
} from "@material-tailwind/react";

export function CreateVehicleFormComponent({
    formData,
    loading,
    handleInputChange,
    handleFileChange,
    handleSelectChange,
    handleSubmit,
    drivers
}) {
    const [errors, setErrors] = useState({});

    const handleInputChangeWithValidation = (e) => {
        const { name, value } = e.target;

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

        if (name === 'registration') {
            const plateNumberPattern = /^[A-Za-z]{3}\d{3}[A-Za-z]{1}$/;
            if (!plateNumberPattern.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    registration: 'Invalid plate number format. Expected format: KAA000A'
                }));
            }
        }

        handleInputChange(e);
    };

    return (
        <Card color="transparent" shadow={false}>
            <form className="m-4" onSubmit={handleSubmit}>
                <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Vehicle Registration
                        </Typography>
                        <Input
                            size="lg"
                            name="registration"
                            value={formData.registration}
                            onChange={handleInputChangeWithValidation}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                        {errors.registration && (
                            <Typography color="red" className="mt-2">
                                {errors.registration}
                            </Typography>
                        )}
                    </div>                   
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Vehicle Capacity
                        </Typography>
                        <Input
                            size="lg"
                            name="capacity"
                            type="number"
                            min={1}
                            value={formData.capacity}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Fleet Number
                        </Typography>
                        <Input
                            size="lg"
                            name="fleet_number"
                            value={formData.fleet_number}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Default Driver
                        </Typography>
                        <Select
                            size="lg"
                            name="driver_id"
                            onChange={(value) => handleSelectChange('driver_id', value)}
                            required
                            options={drivers}
                        >
                            {drivers?.length && drivers.map(driver => (
                                <Option key={driver.id} value={driver.id}>
                                    {driver.username}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Fuel Type
                        </Typography>
                        <Select
                            size="lg"
                            name="fuel_type"
                            onChange={(value) => handleSelectChange('fuel_type', value)}
                            required
                            options={['PETROL', 'DIESEL', 'ELECTRIC']}
                        >
                            <Option value="PETROL">Petrol</Option>
                            <Option value="DIESEL">Diesel</Option>
                            <Option value="ELECTRIC">Electric</Option>
                        </Select>
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Make
                        </Typography>
                        <Input
                            size="lg"
                            name="make"
                            value={formData.make}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Model
                        </Typography>
                        <Input
                            size="lg"
                            name="model"
                            value={formData.model}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Current Mileage
                        </Typography>
                        <Input
                            size="lg"
                            name="current_mileage"
                            type="number"
                            value={formData.current_mileage}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Service Mileage
                        </Typography>
                        <Input
                            size="lg"
                            name="service_mileage"
                            type="number"
                            value={formData.service_mileage}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Next Inspection
                        </Typography>
                        <Input
                            size="lg"
                            name="next_inspection"
                            type="date"
                            value={formData.next_inspection}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            Music Copyright Expiry
                        </Typography>
                        <Input
                            size="lg"
                            name="music_copyright_expiry"
                            type="date"
                            value={formData.music_copyright_expiry}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Typography variant="h6" color="blue-gray" className="mb-3">
                            KM per Litre
                        </Typography>
                        <Input
                            size="lg"
                            name="km_litre"
                            type="number"
                            value={formData.km_litre}
                            onChange={handleInputChange}
                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{ className: "before:content-none after:content-none" }}
                            required
                        />
                    </div>
                    <div className="col-span-1">
                        <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                            {loading ? <Spinner size="small" /> : 'Create Vehicle'}
                        </Button>
                    </div>
                </div>
            </form>
        </Card>
    );
}

export default CreateVehicleFormComponent;
