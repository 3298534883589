// apis/auth.js
import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";
const API_URL = import.meta.env.VITE_API_URL;

export async function fetchTrips(token){
    try {
        const response = await axios.get(`${API_URL}/dash/trips`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchTripBookings(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/${id}/bookings`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function fetchTripRoute(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/${id}/route`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function createTripFunc(token, tripData){
    try {
        const response = await axios.post(`${API_URL}/dash/trips/create`, { ...tripData } ,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
  
        if (response.status === 201) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
  }

  export async function fetchTripDispatch(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/${id}/dispatch`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data || [];
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchAllDispatch (token){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/dispatch`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data || [];
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    } 
}

export async function fetchUnassignedBookings(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/${id}/passenger-pool`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data || [];
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchAllUnassignedBookings (token){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/all/unassigned`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data || [];
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    } 
}


export async function fetchUnassignedResources(token){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/resources`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function createTripDispatchFunc(token, id, payload){
    try {
        const response = await axios.post(`${API_URL}/dash/trips/dispatch/${id}`,{...payload},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchDispatch(token, id){
    try {
        const response = await axios.get(`${API_URL}/dash/trips/dispatch/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
};

export async function updateTripDispatchFunc(token, payload){
    try {
        const response = await axios.post(`${API_URL}/dash/trips/allocation`,{...payload},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}


export async function cancelTripFunction(token, id){
    try {
        console.log(id)
        const response = await axios.post(`${API_URL}/dash/trips/dispatch/suspend`,{dispatchId:id}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}
