import React from "react";
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";

const TripsTable = ({ trips, statistics }) => {
    const headers = ["Trip Code", "From", "To", "Status", "Bookings", "Dispatch Count", "Trip Date"];
    const columns = ["trip_code", "start_point", "end_point", "trip_status", "tripBookings", "trip_dispatch_count","trip_date"]

    return (
        <ReusableTableComponent
            headers={headers}
            columns={columns}
            data={trips}
            statistics={statistics}
            sortableColumns={[0, 1, 2, 3, 4, 5]}
            titleColumnIndex={0}
            searchEnabled={true}
            titleClickable={true}
            titleClickableUrl="/dashboard/trips"
            settingsColumn={false}
            hideCreateButton={true}
            // createEntityLink="/dashboard/trips/create"
        />
    );
};


export default TripsTable;
