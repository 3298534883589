import React, { useEffect, useState } from "react";

const LiveTrackDispatch = ({ dispatch }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [currentDistance, setCurrentDistance] = useState(0);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY;
  const socket_url = import.meta.env.VITE_SOCKET_URL;

  const createMarkerIcon = (statusText) => {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = "https://maps.google.com/mapfiles/kml/shapes/cabs.png";
    img.onload = () => {
      ctx.drawImage(img, 0, 0, 50, 50);
      ctx.font = "12px Arial";
      ctx.fillStyle = "red";
      ctx.fillText(statusText, 10, 60);
    };

    return canvas.toDataURL();
  };

  useEffect(() => {
    const loadScript = () => {
      const existingScript = document.getElementById("maps");

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&avoid=tolls|highways|ferries`;
        script.id = "maps";
        script.async = true;
        script.onload = () => {
          console.log("Google Maps script loaded");
          initMap();
        };
        script.onerror = () => {
          console.error("Failed to load Google Maps script");
        };
        document.head.appendChild(script);
      } else {
        console.log("Google Maps script already loaded");
        initMap();
      }
    };

    const initMap = () => {
      const mapElement = document.getElementById("map");
      if (!mapElement) {
        console.error("Map container not found");
        return;
      }

      try {
        const mapInstance = new window.google.maps.Map(mapElement, {
          zoom: 15,
          center: {
            lat: parseFloat(dispatch?.trip?.route?.start_point ) || parseFloat('-1.2914136370252547'),
            lng: parseFloat(dispatch?.trip?.route?.start_point) || parseFloat(' 36.79139769714726'),
          }
        });

        const customIcon = {
          url: createMarkerIcon("offline"),
          scaledSize: new window.google.maps.Size(50, 50),
        };

        const markerInstance = new window.google.maps.Marker({
          position: {
            lat: parseFloat(dispatch?.trip?.route?.start_point) || parseFloat('-1.2914136370252547'),
            lng: parseFloat(dispatch?.trip?.route?.start_point) || parseFloat(' 36.79139769714726'),
          },
          map: mapInstance,
          title: dispatch.dispatchCode || 'dispatch' ,
          icon: customIcon,
        });

        const directionsRendererInstance = new window.google.maps.DirectionsRenderer({
          map: mapInstance,
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#FF0000",
            strokeOpacity: 0.7,
            strokeWeight: 5,
          },
        });

        setMap(mapInstance);
        setMarker(markerInstance);
        setDirectionsRenderer(directionsRendererInstance);
      } catch (error) {
        console.error("Error initializing map:", error);
      }
    };

    if (!window.google) {
      loadScript();
    } else {
      initMap();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!marker || !map || !directionsRenderer) return;

    const newPosition = {
      lat: parseFloat('-1.2914136370252547'),
      lng: parseFloat(' 36.79139769714726'),
    };
    marker.setPosition(newPosition);
    map.setCenter(newPosition);
    setCurrentDistance(0);

    const socket = new WebSocket(socket_url);

    socket.onopen = () => {
      console.log("WebSocket connection established.");
      // Keep the status as "Offline" until we receive coordinates.
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data && data.lat && data.lng) {
        const newPosition = {
          lat: parseFloat(data.lat) ,
          lng: parseFloat(data.lng) ,
        };
        marker.setPosition(newPosition);
        map.setCenter(newPosition);
        setCurrentDistance(data.distance_travelled);
        setIsOnline(true); // Set online only when we receive valid coordinates.
      } else {
        setIsOnline(false);       
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setIsOnline(false);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
      setIsOnline(false);
    };

    return () => {
      socket.close();
    };
  }, [marker, map, directionsRenderer, dispatch]);

  useEffect(() => {
    const exec_ = () => {
      try{
          if (!map || !directionsRenderer) return;

          const directionsService = new window.google.maps.DirectionsService();
          const origin = {
            lat: parseFloat(dispatch?.trip?.route?.start_point) || parseFloat('-1.2914136370252547'),
            lng: parseFloat(dispatch?.trip?.route?.end_point) || parseFloat(' 36.79139769714726'),
          };
          const destination = {
            lat: parseFloat(dispatch?.trip?.route?.start_point) || parseFloat('-1.2914136370252547'),
            lng: parseFloat(dispatch?.trip?.route?.end_point) || parseFloat(' 36.79139769714726'),
          };
          console.log("directionsService", directionsService);
      
          directionsService.route(
            {
              origin,
              destination,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              console.log("response", response, "status", status);
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(response);
              } else {
                console.error(`Directions request failed due to ${status}`);
              }
            }
          );
      }catch(error){
        console.error(error);
      }
    };
    exec_();
  }, [map, directionsRenderer, dispatch]);

  useEffect(() => {
    if (marker) {
      const iconText = isOnline ? "Online" : "Offline";
      const customIcon = createMarkerIcon(iconText);
      marker.setIcon(customIcon);
    }
  }, [isOnline]);

  return (
    <div className="App">
      <div className="p-2 text-left">
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          From <u>{dispatch?.trip?.route?.start_point}</u> To{" "}
          <u>{dispatch?.trip?.route?.end_point}</u>
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Vehicle: {dispatch?.vehicle?.registration} -{" "}
          {dispatch?.vehicle?.fleetNumber}
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Driver details: {dispatch?.driver?.username},{" "}
          {dispatch?.driver?.email},{" "}
          {dispatch?.driver?.mobileNumber}
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Distance Traveled: {currentDistance}
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Status: <span style={{ color: isOnline ? "green" : "red" }}>{isOnline ? "Online" : "Offline"}</span>
        </p>
      </div>
      <div style={{ width: "100%", height: "600px" }} id="map"></div>
    </div>
  );
};

export default LiveTrackDispatch;
