import { Button } from "@material-tailwind/react";

export function DispatchStatusButtons({ statusCounts }) {
  return (
    <div className="flex w-max gap-4 my-4">
      <Button color="#EDEFF0">Inactive ({statusCounts.inactive})</Button>
      <Button color="green">Dispatched: On the move ({statusCounts.dispatchedMoving})</Button>
      <Button color="yellow">Dispatched: En-route to Pickup ({statusCounts.dispatchedPickup})</Button>
      <Button color="orange">Dispatched: Layover ({statusCounts.cancelled})</Button>
      <Button color="purple">Completed ({statusCounts.completed})</Button>
    </div>
  );
}

export default DispatchStatusButtons;