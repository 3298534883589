import React from 'react';
import { Card, Input, Checkbox, Button, Typography, Spinner, Alert } from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';

export function SecuritySettings({ formData, onFormChange, onSubmit, onForgotPasswordRequest, loading, alert, setAlert }) {
  return (
    <div className=" w-full">
      <Card color="transparent" shadow={false} className="w-3/4 mx-auto">
        <Typography variant="h4" color="blue-gray">
          Reset Password
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Please enter your old password and new password to reset.
        </Typography>
        {alert.status && (
          <div className="w-1/2 mx-auto">
          <GeneralAlert props={{ ...alert, setAlert }} />
        </div>
        )}
        <form className="mt-8 mb-2 w-full max-w-screen-lg" onSubmit={onSubmit}>
          <div className="mb-1 flex flex-col gap-6">
            
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              New Password
            </Typography>
            <Input
              type="password"
              size="lg"
              placeholder="********"
              name="newPassword"
              value={formData.newPassword}
              onChange={onFormChange}
              required
              className="!border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Confirm New Password
            </Typography>
            <Input
              type="password"
              size="lg"
              placeholder="********"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={onFormChange}
              required
              className="!border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
          </div>
         
          {loading ? (
            <Button className="mt-6" fullWidth disabled>
              <Spinner />
            </Button>
          ) : (
            <Button className="mt-6" fullWidth type="submit">
              Reset Password
            </Button>
          )}         
        </form>
      </Card>
    </div>    
  );
}

export default SecuritySettings;
