import React, { useState } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateCAPUserFormComponent from "@/widgets/components/pagecomponents/organisations/createOrgUser";
import { createOrgUserFunc } from "@/apis/organisations";
import GeneralAlert from '@/widgets/alerts/general';
import { useNavigate, useParams } from 'react-router-dom';

export function CreateOrgUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        mobileNumber: '',
        role: '',
        agencyId: id,
        departmentId: '',
    });

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const createUser = await createOrgUserFunc(token, formData);
            if(createUser){
                setLoading(false);
                setAlert({
                    status: true,
                    message: createUser.details,
                    type: 'success',
                });
                setFormData({
                  username: '',
                  email: '',
                  mobileNumber: '',
                  role: '',
                  agencyId: id,
                  departmentId: '',
                });
            }else{
                setLoading(false);
                setAlert({
                    status: true,
                    message: 'Failed to create user.',
                    type: 'error',
                });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create user.',
                type: 'error',
            });
        }
    };

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="mb-8 p-6 flex justify-between">
            <Typography variant="h6" color="white">
                Create Organisation User
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/organisations/${id}`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>

        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">        
          { alert.status ? (
            <div className="w-1/2 mx-auto">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null }
            <CreateCAPUserFormComponent
              formData={formData}
              loading={loading}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              handleSubmit={handleSubmit}
              createEntityLink={`/dashboard/organisations/create-user/${id}`}

            />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateOrgUser;
