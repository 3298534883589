import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import MapComponent from "@/widgets/components/pagecomponents/maintenances/MapComponent";
import RouteTripsComponent from "@/widgets/components/pagecomponents/maintenances/routeTrips";
import RouteStagesComponent from "@/widgets/components/pagecomponents/maintenances/routeStages";
import RouteManagement from "@/widgets/components/pagecomponents/maintenances/routeManagement";
import { fetchRouteFunc } from "@/apis/maintenances";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Cog8ToothIcon,
  ArrowLeftCircleIcon,
  ExclamationCircleIcon,
  MapIcon
} from "@heroicons/react/24/solid";

export function RouteDetailsComponent() {
  const [route, setRoute] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('map');
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token');
      const route = await fetchRouteFunc(token, id);
      if (route) {
          setRoute(route);
          setLoading(false);
      } else {
        setAlert({
          status: true,
          message: "Unable to fetch route details at this time.",
          type: 'error',
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      sendSentryAlert(error);
      setAlert({
        status: true,
        message: 'We are having trouble fetching route data. Try again.',
        type: 'error',
      });
    }
  };
  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    

    fetchData();
  }, []);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };


  return (
    <div className="m-2">
      <div className="mb-4">
        <div class="p-2 text-left">
            <h6 class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                Route Code: {route.route_code}
            </h6>
            <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                From: <span className="font-bold">{route.start_point}</span> To <span className="font-bold">{route.end_point}</span>
            </p>
            <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                Route Length: <span className="font-bold">{route.route_length}</span> 
            </p>
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-12">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate('/dashboard/maintenances')}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'map' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('map')}
            >
              <MapIcon className="h-5 w-5 mr-2" />
              Map
            </button>     
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'stages' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('stages')}
            >
            <ExclamationCircleIcon className="h-5 w-5 mr-2" />
                Stops
            </button> 
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('management')}
            >
              <Cog8ToothIcon className="h-5 w-5 mr-2" />
              Management
            </button>    
          </div>
        </div>
        <div className="flex-1">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                {loading ? (
                  <Typography>
                    <Spinner />
                  </Typography>
                ) : (
                  alert.status ? (
                    <div className="w-1/2 mx-auto">
                      <GeneralAlert props={{ ...alert, setAlert }} />
                    </div>
                  ) : (
                    <>
                      {selectedNav === 'map' && (
                        <div className="p-4">
                            <MapComponent 
                              setStart={route.start_point} 
                              setEnd={route.end_point} 
                              setDistance={route.route_length}
                              waypoints={route.stages || []}
                            />
                        </div>
                      )}
                      {selectedNav === 'trips' && (
                        <div className="p-4">
                          <RouteTripsComponent id={id} />
                        </div>
                      )}   
                      {selectedNav === 'stages' && (
                        <div className="p-4">
                          <RouteStagesComponent  id={id} />
                        </div>
                      )}      
                      {selectedNav === 'management' && (
                        <div className="p-4">
                          <RouteManagement  formData={route} id={id} refreshData={fetchData} />
                        </div>
                      )}                  
                    </>
                  )
                )}
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default RouteDetailsComponent;
