import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  Alert,
  Select,
  Checkbox,
  Option,
} from "@material-tailwind/react";
import { ChevronUpDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { UploadModal } from "@/widgets/modals/uploadBulk";
import { createTripDispatchFunc} from "@/apis/trips";
import GeneralAlert from '@/widgets/alerts/general';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  const date = new Date(dateString);
  if (isNaN(date)) return dateString;
  return date.toLocaleDateString('en-GB', options).replace(',', '');
};

export const DispatchTableComponent = ({
  headers,
  columns,
  data,
  sortableColumns,
  searchEnabled,
  titleClickable,
  titleClickableUrl,
  titleColumnIndex,
  settingsColumn,
  handleSettingsEditLink,
  createEntityLink,
  drivers,
  vehicles,
  id,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newDispatch, setNewDispatch]=useState({
    defaultDriverId:'',
    defaultVehicleId:'',
    tripBookingIds:[]

  });
  const [loading, setLoading]=useState(false);
  const [alert, setAlert] = useState({ status: false, message: '',type: '',});  
  const [createAlert, setCreateAlert] = useState({ status: false, message: '',type: '',});  

  const handleChange = (name,value) => {
    console.log("name: " + name)
    console.log("value: " + value)
    setCreateAlert({status: false, message: '',type: ''})
    setNewDispatch({ ...newDispatch,[name]: value })
  }

  const rowsPerPage = 10;
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleOpenDelete = () => setOpenDelete(!openDelete);
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  const handleOpenEdit = (record) => {
    if (handleSettingsEditLink) {
      navigate(`${handleSettingsEditLink}/${record.id}`);
    }
  };

  const handleRowSelection = (id) => {
    setSelectedRows((selectedRows) =>
      selectedRows.includes(id) ? selectedRows.filter((rowId) => rowId !== id)
        : [...selectedRows, id]
    );
  };
  
  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.bookingId));
    }
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    if (!sortConfig.key) {
      return data;
    }
    return data.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = sortedData().filter((row) =>
    Object.values(row).some(
      (value) => typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = headers;
    const tableRows = [];

    paginatedData.forEach((row) => {
      const rowData = columns.map((col) => {
        const value = row[col];
        return typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T/)
          ? formatDate(value)
          : value;
      });
      tableRows.push(rowData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
    });

    doc.save("table.pdf");
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      paginatedData.map((row) => {
        const rowData = {};
        headers.forEach((header, index) => {
          let value = row[columns[index]];
          if (typeof value === "string" && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
            value = formatDate(value);
          }
          rowData[header] = value;
        });
        return rowData;
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "table.xlsx");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  
    // Check if required fields are selected
    if (!newDispatch.defaultDriverId || !newDispatch.defaultVehicleId) {
      setCreateAlert({
        status: true,
        message: 'Please select both a driver and a vehicle.',
        type: "error"
      });
      return; // Stop form submission if required fields are empty
    }
  
    try {
      const token = localStorage.getItem('token');
      const requestObject = {
        ...newDispatch,
        tripBookingIds: selectedRows
      };
      
      const createTripDispatch = await createTripDispatchFunc(token, id, requestObject);
      if (createTripDispatch) {
        setAlert({
          status: true,
          message: 'Trip dispatch created successfully.',
          type: "success"
        });
        setNewDispatch({
          defaultDriverId: '',
          defaultVehicleId: '',
          tripBookingIds: []
        });

      setLoading(false);
      toggleDialog();
      } else {
        setCreateAlert({
          status: true,
          message: 'Failed to create dispatch.',
          type: "error"
        });
      }
    } catch (error) {
      console.error("Error creating dispatch:", error);
      setAlert({
        status: true,
        message: 'Service is currently unavailable. Please try again later.',
        type: "error"
      });
      setLoading(false);
      toggleDialog();
    } 
  };
  

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-2 flex items-center justify-between gap-8">
          <div>
            <div className="flex gap-4 mt-4">
              {searchEnabled && (
                <div className="w-full md:w-72">
                  <Input
                    label="Search"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              )}
            </div>
          </div>
          { id && (
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                {selectedRows.length > 0  ? (
                  <Button className="flex items-center gap-3" size="sm" onClick={toggleDialog}>
                    Create Dispatch
                  </Button>
                ) : (
                  <Button className="flex items-center gap-3" size="sm" disabled>
                    Create Dispatch
                  </Button>
                )}
                <Button className="flex items-center gap-3" size="sm" onClick={exportToExcel}>
                  Export to Excel
                </Button>
                <Button className="flex items-center gap-3" size="sm" onClick={exportToPDF}>
                  Export to PDF
                </Button>
              </div>
          )}         
        </div>
      </CardHeader>
      <CardBody className="px-0 overflow-auto">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              <th  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                <Checkbox
                  checked={selectedRows.length === data.length}
                  onChange={handleSelectAll}
                />
              </th>
              {headers.map((header, index) => (
                <th
                  key={header}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                  onClick={() => sortableColumns.includes(header) && requestSort(header)}
                >
                  <div className="flex items-center justify-between gap-2">
                    {header}
                    {sortableColumns.includes(header) && (
                      <ChevronUpDownIcon className="h-4 w-4" />
                    )}
                  </div>
                </th>
              ))}
              {!settingsColumn && <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"></th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((record, rowIndex) => (
              <tr
                key={record.id}
                className="even:bg-blue-gray-50/50 hover:bg-blue-gray-50 cursor-pointer"
              >
                <td className="p-4">
                  <Checkbox
                    checked={selectedRows.includes(record.bookingId)}
                    onChange={() => {
                      handleRowSelection(record.bookingId)
                    }}
                  />
                </td>
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="p-4"
                    onClick={() => {
                      if (titleClickable && colIndex === titleColumnIndex) {
                        navigate(`${titleClickableUrl}/${record.id}`);
                      }
                    }}
                  >
                    {typeof record[column] === "string" && record[column].match(/^\d{4}-\d{2}-\d{2}T/)
                      ? formatDate(record[column])
                      : record[column]}
                  </td>
                ))}
                {!settingsColumn && (
                  <td className="p-4">
                    <Button
                      className="flex items-center gap-3"
                      size="sm"
                      onClick={() => handleOpenEdit(record)}
                    >
                      View
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="border-t border-blue-gray-50 p-4">
        <div className="flex items-center justify-between">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPages}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              color="blue-gray"
              size="sm"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              color="blue-gray"
              size="sm"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </div>
      </CardFooter>

      <Dialog open={isDialogOpen} handler={toggleDialog}>
        <DialogHeader>Create Dispatch</DialogHeader>
        <DialogBody divider>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-4">
              <Select
                label="Select Driver"
                name="defaultDriverId"
                value={newDispatch.defaultDriverId}
                onChange={(e) => handleChange("defaultDriverId", e)}
                required
              >
                {drivers.map((driver) => (
                  <Option key={driver.id} value={driver.id}>
                    {driver.username}
                  </Option>
                ))}
              </Select>

              <Select
                label="Select Vehicle"
                name="defaultVehicleId"
                value={newDispatch.defaultVehicleId}
                onChange={(e) => handleChange("defaultVehicleId", e)}
                required
              >
                {vehicles.map((vehicle) => (
                  <Option key={vehicle.id} value={vehicle.id}>
                  {vehicle.fleet_number} -  {vehicle.registration}
                  </Option>
                ))}
              </Select>
            </div>

            {createAlert.status && (
              <Alert color={createAlert.type} className="mt-4">
                {createAlert.message}
              </Alert>
            )}

            <div className="flex justify-end gap-4 mt-6">
              <Button type="button" variant="text" color="red" onClick={toggleDialog}>
                Cancel
              </Button>
              <Button type="submit" color="green" disabled={loading}>
                {loading ? "Creating..." : "Create Dispatch"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
      <UploadModal isOpen={isModalOpen} onClose={closeModal} />
    </Card>
  );
};

DispatchTableComponent.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortableColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchEnabled: PropTypes.bool,
  titleClickable: PropTypes.bool,
  titleClickableUrl: PropTypes.string,
  titleColumnIndex: PropTypes.number,
  settingsColumn: PropTypes.bool,
  handleSettingsEditLink: PropTypes.string,
  createEntityLink: PropTypes.string,
  sampleFileData: PropTypes.arrayOf(PropTypes.object).isRequired,
  drivers: PropTypes.arrayOf(PropTypes.object).isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
};
