import React, { useState, useEffect } from 'react';
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from '@/widgets/alerts/general';
import { fetchAllDispatch } from "@/apis/trips"
import {
    Spinner
  } from "@material-tailwind/react";

const AllDispatchComponent = () => {
    const headers = ["Dispatch Code", "Trip date","Status", "From", "To", "Bookings", "Vehicle", "Driver", ];
    const columns = ["dispatchCode", "trip_date","trip_status","routeFrom","routeTo","bookingsCount","vehicleRegistration","driverName"]
    const [alert, setAlert] = useState({ status: false, message: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [dispatch, setDispatch] = useState([]);
  
    useEffect(() => {
      setAlert({ status: false, message: '', type: '' });
      setLoading(true);
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
          const dispatchRecords = await fetchAllDispatch(token);
          if (dispatchRecords) {
            setDispatch(dispatchRecords);
            setLoading(false);
          } else {
            setAlert({
              status: true,
              message: "Unable to fetch Dispatch records at this time.",
              type: 'error',
            });
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          sendSentryAlert(error);
          setAlert({
            status: true,
            message: 'We are having trouble fetching Dispatch data. Try again.',
            type: 'error',
          });
        }
      };
  
      fetchData();
    }, []);

    if(loading){
        return <Spinner />
    }
   
    return (
         <>      
            { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
            <ReusableTableComponent
                headers={headers}
                columns={columns}
                data={dispatch}
                sortableColumns={[0,1, 2, 3, 4, 5,6]}
                titleColumnIndex={0}
                searchEnabled={true}
                titleClickable={true}
                titleClickableUrl="/dashboard/dispatch"
                settingsColumn={false}
                hideCreateButton={true}
            />
        </>
    );
};


export default AllDispatchComponent;
