import React, { useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { UserCircleIcon, BuildingOfficeIcon } from "@heroicons/react/24/solid";

import PassengerInvoicesComponent from "@/widgets/components/pagecomponents/finances/passengerInvoicesComponent";
import CompanyInvoicesComponent from "@/widgets/components/pagecomponents/finances/companyInvoiceComponent";

function InvoicesPage() {
  const data = [
    {
      label: "Passengers",
      value: "passengers",
      icon: UserCircleIcon,
      component: <PassengerInvoicesComponent />
    },
    {
      label: "Organizations",
      value: "organizations",
      icon: BuildingOfficeIcon,
      component: <CompanyInvoicesComponent />
    },
  ];

  const [activeTab, setActiveTab] = useState("passengers");

  return (
    <Tabs value={activeTab} onChange={(val) => setActiveTab(val)} className="w-full">
      <TabsHeader>
        {data.map(({ label, value, icon }) => (
          <Tab key={value} value={value}>
            <div className="flex items-center gap-2">
              {React.createElement(icon, { className: "w-5 h-5" })}
              {label}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, component }) => (
          <TabPanel key={value} value={value}>
            {component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default InvoicesPage;
