import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

export function Footer({ brandName, brandLink, routes }) {
  const year = new Date().getFullYear();

  return (
    <footer className="py-2">
      <div className="flex w-full flex-wrap items-center justify-center gap-6 px-2 ">
        <Typography variant="small" className="font-bold text-inherit">
          &copy; {" Copyright "} {year}{" "}
          
            {brandName}
        </Typography>
        {" | "}
        <ul className="flex items-center gap-4">
          {routes.map(({ name, path }) => (
            <li key={name}>
              <Typography
                as="a"
                href={path}
                target="_blank"
                variant="small"
                className="py-0.5 px-1 font-normal text-inherit transition-colors hover:text-blue-500"
              >
                <a
                    href={brandLink}
                    target="_blank"
                    className="transition-colors hover:text-blue-500 font-bold"
                  >
                  {name}
                
                </a>
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  brandName: "Sideways Tours",
  brandLink: "https://ujuzicode.com",
  routes: [
    { name: "Built by Ujuzicode Ltd", path: "https://ujuzicode.com/" },
  ],
};

Footer.propTypes = {
  brandName: PropTypes.string,
  brandLink: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
