import React from "react";
import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
const DriverDetailsPageComponent = ({driver}) => {    

    return (
        <div className="mb-12 flex flex-col md:flex-row justify-between gap-6">
            <div className="flex flex-col md:flex-row justify-between w-full ">
                <Card className="w-full p-5 m-5 bg-[#F5F7F8]">
                    
                </Card>
                <Card className="w-full p-5 m-5 bg-[#F5F7F8]">
                    <CardBody>
                        <Typography variant="h4" color="blue-gray">
                            User Name: {driver?.username  || '-'}
                        </Typography>
                        <Typography variant="h4" color="blue-gray">
                            Email: {driver?.email  || '-'}
                        </Typography>
                        <Typography variant="h4" color="blue-gray">
                            Phone Number:  {driver?.mobileNumber  || '-'}
                        </Typography>
                        <Typography variant="h4" color="blue-gray">
                            mileage Covered(kms): {driver?.distance_covered  || 0 }
                        </Typography>
                        <Typography variant="h4" color="blue-gray">
                            Assigned Vehicle: {driver?.default_vehicle  || '-' }
                        </Typography>
                    </CardBody>
                </Card>
            </div>
            
        </div>
    );
};

export default DriverDetailsPageComponent;
