import React, { useState, useEffect } from "react";
const API_KEY = import.meta.env.VITE_GOOGLEMAPS_API_KEY;
import axios from 'axios';

const TripRoute = ({ trip }) => {
  const [routeLength, setRouteLength] = useState(null);

  const origin = trip?.routeDetails?.start_point;
  const destination = trip?.routeDetails?.end_point;
  const route_code = trip?.routeDetails?.route_code;
  const waypointsParam = trip?.bookedStages?.map(point => `${point.name}`).join('|') || [];

  const googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/directions?key=${API_KEY}&origin=${origin}&destination=${destination}&waypoints=${waypointsParam}&avoid=tolls|highways`;

  // Fetch route length using Distance Matrix API
  useEffect(() => {
    if (origin && destination) {
      const distanceMatrixUrl = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${API_KEY}&waypoints=${waypointsParam}&units=imperial`;

      axios.get(distanceMatrixUrl)
        .then(response => {
          const distance = response.data.rows[0].elements[0].distance;
          if (distance) {
            setRouteLength(distance.text); // Distance in a readable format (e.g., "12.4 km")
          }
        }).catch(error => console.error("Error fetching distance matrix:", error));
      }
  }, [origin, destination]);

  return (
    <div className="App">
      <div className="p-2 text-left">
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Route Code <u>{route_code}</u>
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Origin : {origin}
        </p>
        <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
          Destination: {destination}
        </p>
        {routeLength && (
          <p className="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            Route Length: {routeLength}
          </p>
        )}
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <iframe
          title="Google Maps Directions"
          width="100%"
          height="600"
          style={{ border: 0 }}
          src={googleMapsEmbedUrl}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default TripRoute;
