import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Typography, Spinner } from "@material-tailwind/react";
import CreateOrganisationComponent from "@/widgets/components/pagecomponents/organisations/createOrganisation";
import GeneralAlert from '@/widgets/alerts/general';
import  {fetchSupercapOrganisations, createAgencyFunc}  from "@/apis/organisations";
import { useNavigate } from 'react-router-dom';


export function CreateOrganisation() {
    const [agencyData, setAgencyData] = useState({
        name: '',
        type: '',
        parentAgencyId: '',
    });
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ status: false, message: '',type:'' });  
    const [organisations, setOrganisations] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAgencyData({ ...agencyData, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        setAgencyData({ ...agencyData, [name]: value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setAlert({ status: false, message: '' });
        const token = localStorage.getItem('token');
        try {
            const createAgency = await createAgencyFunc(token, agencyData);
            if(createAgency){
              setLoading(false);
              setAlert({
                  status: true,
                  message: 'Organsation created successfully.',
                  type:"success"
              });
              setAgencyData({
                name: '',
                type: '',
                parentAgencyId: '',
              })
            }else{
              setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'Failed to create organisation.',
                type:"error"
            });
            }
            
        } catch (error) {
            setLoading(false);
            setAlert({
                status: true,
                message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
                type:"error"
            });
        }
    };

    useEffect(() => {    
        const fetchData = async () => {
          try {
              const token = localStorage.getItem('token');
              const organisations = await fetchSupercapOrganisations(token);
              console.log("Response with orgs VIew level: ",organisations)
              if (organisations) {
                const orgs = organisations.data;
                setOrganisations(orgs);          
                setLoading(false);
              } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch organisations.",
                    type: 'error',
                });
                setLoading(false);
              }
          } catch (error) {
            console.log(error)
              setLoading(false);
              sendSentryAlert(error);
              setAlert({
                  status: true,
                  message: 'We are having trouble fetching organisation.',
                  type: 'error',
                });
          }
      };
    
        fetchData();
      }, []);

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card>
        <CardHeader variant="gradient" color="gray" className="flex justify-between mb-8 p-6">
        <Typography variant="h6" color="white">
                Create Organisation
            </Typography>
            <div className="flex items-center">
                <button onClick={() => navigate(`/dashboard/organisations`)} className="text-white underline">
                    Back
                </button>
            </div>
        </CardHeader>
        <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        {  alert.status ? (
              <div className="w-1/2 mx-auto">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null }
        <CreateOrganisationComponent
          formData={agencyData}
          organisations={organisations}
          loading={loading}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleSubmit={handleSubmit}
        />
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateOrganisation;
