import React, { useState, useEffect } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { updateTripDispatchFunc } from "@/apis/trips";
import { fetchUnassignedResources } from "@/apis/trips"
import sendSentryAlert from "@/utils/sentryUtil";

export function MaintainDispatchComponent({ dispatch }) {
  const [loading, setLoading] = useState(false);
  const [dispatchData, setDispatchData] = useState({});
  const [driverData, setDriverData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [activeTab, setActiveTab] = useState("editDetails");
  const [isActivated, setIsActivated] = useState((dispatch?.status === "ACTIVE") ? true : false);
  const [errors, setErrors] = useState({});
  
  const handleSelectChange = (name, value) => {
    setDispatchData({ ...dispatchData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '', type:'' });
    const token = localStorage.getItem('token');
    try {
      const updateDispatchData = {
        dispatchId:dispatch.id,
        driverId: dispatchData.driverId
      };
  
      const updateDispatch = await updateTripDispatchFunc(token, updateDispatchData);
      if (updateDispatch) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Dispatch updated successfully.',
          type: 'success',
        });
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update Dispatch.',
          type: 'error',
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: 'error',
      });
    }
  };

  // const handleToggleActivation = async () => {
  //   setLoading(true);
  //   const token = localStorage.getItem('token');
  //   try {
  //     const updateVehicle = await activateDeactivateDriverFunction(token, id);
  //     if (updateVehicle) {
  //       setLoading(false);
  //       setAlert({
  //         status: true,
  //         message: 'Driver updated successfully.',
  //         type: "success"
  //       });
  //       fetchData();
  //       setIsActivated(!isActivated);
  //     } else {
  //       setLoading(false);
  //       setAlert({
  //         status: true,
  //         message: 'Failed to update driver.',
  //         type: "error"
  //       });
  //     }

  //   } catch (error) {
  //     setLoading(false);
  //     setAlert({
  //       status: true,
  //       message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
  //       type: "error"
  //     });
  //   }
  // };

  useEffect(()=>{
    const fetchData = async () => {
        try {
            setLoading(true);
          const token = localStorage.getItem('token');
          const records = await fetchUnassignedResources(token);
          if (records) {
            setVehicleData(records.unallocatedVehicles);
            setDriverData(records.unallocatedDrivers);
          } else {
            setAlert({
              status: true,
              message: "Unable to fetch records.",
              type: 'error',
            });
          }
        } catch (error) {
            console.log(error);
          sendSentryAlert(error);
          setAlert({
            status: true,
            message: 'We are having trouble fetching records.',
            type: 'error',
          });
        } finally {
          setLoading(false);
        } 
      };
      fetchData();
  },[])

  if(loading){
    return (
    <Spinner />
    )
  }

  return (
    <Card color="transparent" shadow={false}>
      <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
        <TabsHeader>
          <Tab value="editDetails">Edit Details</Tab>
          <Tab value="activateDeactivate">Activate/Deactivate</Tab>
        </TabsHeader>
          {alert.status ? (
            <div className="w-1/2 m-6 ">
              <GeneralAlert props={{ ...alert, setAlert }} />
            </div>
          ) : null}
        <TabsBody>
          <TabPanel value="editDetails">
            <form className="m-4" onSubmit={handleSubmit}>
              <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6"> 
              
                
                <div className="col-span-1">
                    <Typography variant="h6" color="blue-gray" className="mb-3">
                        New Allocate new Vehicle
                    </Typography>
                <Select
                        size="lg"
                        name="vehicleId"
                        onChange={(value) => handleSelectChange('vehicleId', value)} 
                        disabled                           
                    >
                        { vehicleData.length && vehicleData.map(vehicle => (
                            <Option key={vehicle.id} value={vehicle.id}>
                                {vehicle.registration} - {vehicle.fleet_number}
                            </Option>
                        ))}
                    </Select> 
                </div>
                
                <div className="col-span-1">
                    <Typography variant="h6" color="blue-gray" className="mb-3">
                        Assign New Driver
                    </Typography>
                    <Select
                        size="lg"
                        name="driverId"
                        onChange={(value) => handleSelectChange('driverId', value)} 
                    >
                        { driverData.length && driverData.map(driver => (
                            <Option key={driver.driverId} value={driver.driverId}>
                                {driver.username}
                            </Option>
                        ))}
                    </Select> 
                </div>
              <div className="col-span-1">
                <Button type="submit" size="lg" fullWidth >
                  {loading ? (
                    <Spinner color="white" size="sm" />
                  ) : (
                    'Update Driver'
                  )}
                </Button>
              </div>
              </div>
            </form>
          </TabPanel>
          <TabPanel value="activateDeactivate">
            <div className="m-4">
              <Typography variant="body1" color="blue-gray" className="mb-6">
                Dispatch currenty {isActivated ? 'Active' : 'Inactive'}. This means {isActivated ? 'all' : 'no'} operations can be done by this dispatch.<br />Please make sure to review the dispatch details before making any changes.
              </Typography>
              { loading ? (
                <Button>
                  <Spinner />
                </Button>
              ) : (
                <Button color={isActivated}>
                  Cancel Dispatch
                </Button>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
    </Card>
  );
}

export default MaintainDispatchComponent;
