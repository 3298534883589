import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import BookingDetailsComponents from "@/widgets/components/pagecomponents/bookings/bookingDetails";
import ManageTrip from "@/widgets/components/pagecomponents/trips/tripManagement";

import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CloudIcon,
  Cog8ToothIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/solid";
import { fetchBookingDetails } from "@/apis/bookings";

export function BookingDetails() {
  const [bookingDetails, setBookingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('details');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const fetchBookingData = await fetchBookingDetails(token, id);
            if (fetchBookingData) {
                setBookingDetails(fetchBookingData.data);
            } else {
                setAlert({
                    status: true,
                    message: "Unable to fetch booking Details..",
                    type: 'error',
                });
            }
        } catch (error) {
            setAlert({
            status: true,
            message: 'We are having trouble fetching booking Details. Try again Later.',
            type: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, [id]);

    const handleNavClick = (navItem) => {
        setSelectedNav(navItem);
    };

    let content;
    switch (selectedNav) {
        case 'details':
            content = <div>Details</div>;
            break;    
        default:
            content = null;
    }

    return (
        <div className="m-2">
        <div className="mb-4 flex w-full">
            <div class="w-full md:w-1/2 sm:w-1/2 p-2 text-left">
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Passenger Name: {bookingDetails?.passenger?.username || ''} 
                </p>
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Placed On: {bookingDetails?.created_at || '-'} 
                </p>
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Trip Date: {bookingDetails?.trip_date || '-'} 
                </p>               
            </div>
            <div class="w-full md:w-1/2 sm:w-1/2 p-2 text-left">
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Route: {bookingDetails?.route?.start_point || '-'} To {bookingDetails?.route?.end_point || '-'}
                </p>
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Stage: {bookingDetails?.route?.stage || '-'}
                </p>
                <p class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
                    Trip Category: {bookingDetails?.trip?.trip_category || '-'} 
                </p>  
            </div>
        </div>
        <div className="mt-12 mb-8 flex flex-row gap-12">
            <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
            <div className="flex flex-col gap-2">
            <button
                className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
                onClick={() => navigate('/dashboard/bookings')}
                >
                <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
                Back
                </button>
                <button
                className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'details' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                onClick={() => handleNavClick('details')}
                >
                <CloudIcon className="h-5 w-5 mr-2" />
                Bookings Details
                </button>
            
                <button
                className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'action' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
                onClick={() => handleNavClick('action')}
                >
                <Cog8ToothIcon className="h-5 w-5 mr-2" />
                Action
                </button>
            </div>
            </div>
            <div className="flex-1">
            <Card>
                <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                    {loading ? (
                    <Typography>
                        <Spinner />
                    </Typography>
                    ) : (
                <>
                        {alert.status ? (
                            <div className="w-1/2 mx-auto m-3">
                                <GeneralAlert props={{ ...alert, setAlert }} />
                            </div>
                        ) : null }
                        
                        
                        {selectedNav === 'details' && (
                            <div className="p-4">
                                <BookingDetailsComponents bookingDetails={bookingDetails} />
                            </div>
                        )}                     

                        {selectedNav === 'management' && (
                            <div className="p-4">
                            <ManageTrip />
                            </div>
                        )}


                    </>
                    )}
                    
                </CardBody>
            </Card>
            </div>
        </div>
        </div>
    );
}

export default BookingDetails;
