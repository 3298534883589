import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const UsersTable = ({ users, statistics, handleSettingsClick }) => {
  const headers = ["Username", "Email", "Mobile Number", "Roles", "Status", "Last Activity"];
  const columns = ["username", "email", "mobileNumber","role","status","lastActivity"]
  const renderRow = (user) => (
    <>
      <td>
        <Typography variant="body" color="blue-gray" className="font-semibold">
          {user.username}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.email}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user.mobileNumber || "-"}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user?.role}
        </Typography>
      </td>
      <td>
        <Typography variant="body" color="blue-gray" className="font-normal">
          {user?.lastActivity?.length > 0 ? formatDate(user?.lastActivity[0]?.created_at) : "-"}
        </Typography>
      </td>
    </>
  );  

  return (
    <ReusableTableComponent
      headers={headers}
      columns={columns}
      data={users}
      renderRow={renderRow}
      statistics={statistics}
      sortableColumns={[0, 1, 2, 3, 4, 5]}
      titleColumnIndex={0}
      searchEnabled={true}
      titleClickable={true}
      titleClickableUrl="/dashboard/users"
      settingsColumn={false}
      handleSettingsClick={handleSettingsClick}
      createEntityLink="/dashboard/users/create"
    />
  );
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      mobileNumber: PropTypes.string,
      roles: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      lastActivity: PropTypes.arrayOf(
        PropTypes.shape({
          created_at: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  handleSettingsClick: PropTypes.func.isRequired
};

export default UsersTable;
