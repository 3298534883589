import React, { useEffect, useState } from "react";
import {
  Spinner,
  Typography
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import BookingSummary from "@/widgets/charts/bookings-summary"
import CustomStatisticsComponent from "@/widgets/charts/trips-statistics"


export function AdminUserDashboard() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();



  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  // if (error || !data) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-gray-100">
  //       <Card className="border border-red-300 shadow-sm">
  //         <CardHeader
  //           floated={false}
  //           shadow={false}
  //           color="red"
  //           className="m-0 p-6"
  //         >
  //           <Typography variant="h6" color="red" className="mb-2">
  //             Error
  //           </Typography>
  //           <Typography
  //             variant="small"
  //             className="flex items-center gap-1 font-normal text-red-600"
  //           >
  //             <strong>500</strong> - Page Data Unavailable
  //           </Typography>
  //         </CardHeader>
  //         <CardBody className="pt-0">
  //           <Typography variant="small" className="text-gray-600">
  //             We encountered an error while trying to fetch the data. Please
  //             try again later.
  //           </Typography>
  //         </CardBody>
  //       </Card>
  //     </div>
  //   );
  // }

  return (
    <div className="mb-4">
        <CustomStatisticsComponent />    
      <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-2">
        <BookingSummary />
      </div>
    </div>
  );
}

export default AdminUserDashboard;
