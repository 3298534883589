import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import MaintainDispatchComponent from "@/widgets/components/pagecomponents/trips/MaintainDispatchComponent";
import BookingsDetailsComponent from "@/widgets/components/pagecomponents/trips/BookingsDetailsComponent";
import LiveTrackDispatch from "@/widgets/components/pagecomponents/trips/liveTrackDispatchComponent";
import ReUsableMapComponent from "@/widgets/components/pagecomponents/trips/ReusableMapComponent" 
import { fetchDispatch } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import { MapPinIcon, ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

export function DispatchDetails() {
  const [dispatch, setDispatch] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('management');
  const { id } = useParams();
  const navigate = useNavigate();

  const determineLiveButtonColor = (status) => {
    try {
      console.log(status);
      let color;
  
      switch (status) {
        case "NEW":
          color = "blue-500";
          break;
        case "DECLINED":
        case "CANCELLED":
          color = "red-500";
          break;
        case "DISPATCHED":
          color = "yellow-500";
          break;
        case "LAYOVER":
          color = "orange-500";
          break;
        case "COMPLETED":
          color = "purple-500";
          break;
        case "ACTIVE":
            color ="green-500";
            break;
        default:
          color = "blue-500";
          break;
      }
  
      console.log(color);
      return color;
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const dispatch = await fetchDispatch(token, id);
        if (dispatch) {
            setDispatch(dispatch);
            console.log('dispatch', dispatch);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch dispatch at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching dispatch. Try again.',
          type: 'error',
        });
      }
    };

    fetchData();
  }, []);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };
 let content;
  switch (selectedNav) {
    case 'bookings':
      content = <div>Bookings</div>;
      break;
    case 'management':
      content = <div>Maintenance</div>;
      break;
    case 'live':
      content = <div>Live</div>;
      break;
    default:
      content = null;
  }

  return (
    <div className="m-2">
      <div className="mb-4">
        <div class="p-2 text-left">
          
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-12">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate(`/dashboard/trips/${dispatch?.trip?.id}`)}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'bookings' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('bookings')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Bookings
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg bg-${determineLiveButtonColor(dispatch.status)} text-black`}
              onClick={() => handleNavClick('live')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Live
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('management')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Management
            </button>
          </div>
        </div>
        <div className="flex-1">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                {loading ? (
                  <Typography>
                    <Spinner />
                  </Typography>
                ) : (
                  alert.status ? (
                    <div className="w-1/2 mx-auto">
                      <GeneralAlert props={{ ...alert, setAlert }} />
                    </div>
                  ) : (
                    <>
                      
                    {selectedNav === 'bookings' && (
                        <div className="p-4">
                          <BookingsDetailsComponent dispatch={dispatch}/>
                        </div>
                      )}   
                      {selectedNav === 'management' && (
                        <div className="p-4">
                          <MaintainDispatchComponent dispatch={dispatch}/>
                        </div>
                      )}   
                      {selectedNav === 'live' && (
                        <div className="p-4">
                          { dispatch.status === "ACTIVE" ? (
                            <LiveTrackDispatch dispatch={dispatch}/>
                          ):(
                            <ReUsableMapComponent 
                              origin={dispatch.trip?.route?.start_point} 
                              destination={dispatch.trip?.route?.end_point} 
                              route_code={dispatch.trip?.route?.route_code}
                              status={dispatch.status}
                            />
                          )}
                        </div>
                      )}  
                    </>
                  )
                )}
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DispatchDetails;
