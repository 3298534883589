import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";
import { fetchNestedOrganisations } from "@/apis/organisations";
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', options).format(date);
}
const OrganisationsTable = ({ id }) => {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const orgs = await fetchNestedOrganisations(token, id);
        if (orgs) {
          setOrgs(orgs.data);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch organizations.",
            type: 'error',
          });
        }
      } catch (error) {
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching organizations.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const headers = ["name", "Staff count", "Agency Type", "Date Created"];
  const columns = ["name", "staffcount","agency_type","date_created"]

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={orgs || []}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        hideCreateButton={true}
      />
    </>
  );
};



export default OrganisationsTable;
