import axios from 'axios';
import sendSentryAlert from "@/utils/sentryUtil";

const API_URL = import.meta.env.VITE_API_URL;

export async function resetPassword(token, oldPassword, newPassword) {
    try {
        const response = await axios.post(`${API_URL}/dash/auth/pass-reset`, {
            newPassword
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function requestPasswordReset(email) {
    try {
        const response = await axios.post(`${API_URL}/auth/forgot-password`, { email });

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error.message);
        return false;
    }
}

export async function fetchUserLogs(token){
    try {
        const response = await axios.get(`${API_URL}/dash/auth/user-logs`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        if(response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (error) {
        sendSentryAlert(error);
        return false;
    }
}
