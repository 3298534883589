import React, { useState, useEffect } from "react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import GeneralAlert from "@/widgets/alerts/general";

const BookingsTableComponent = ({ bookings, statistics }) => {

  const headers = ["Passenger Name", "Route", "Booking Status", "Category", "Booking Date", "Trip Date"];
  const columns = ["username","route_code","booking_status","trip_category", "booking_date","trip_date"]

  return (
    <>
      
      { alert.status && <GeneralAlert props={{ ...alert, setAlert }} /> }
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={bookings || []}
        statistics={statistics}
        sortableColumns={[0, 1, 2, 3, 4, 5]}
        titleColumnIndex={0}
        searchEnabled={true}
        settingsColumn={false}
        hideCreateButton={true}
        titleClickable={true}
        titleClickableUrl="/dashboard/booking"
      />
    </>
  );
};



export default BookingsTableComponent;
