import React, { useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import ChargesComponent from "@/widgets/components/pagecomponents/finances/chargesComponent";
import InvoicesPage from "@/widgets/components/pagecomponents/finances/invoicesPageComponent"
function FinancesPage() {
  const [activeTab, setActiveTab] = useState("charges");

  const data = [
    {
      label: "Charges",
      value: "charges",
      component: <ChargesComponent />,
    },{
        label: "Invoices",
        value: "invoices",
        component: <InvoicesPage />,
      }
  ];

  return (
    <Tabs value={activeTab} className="w-full">
      <div className="w-full md:w-1/2 lg:w-1/3">
        <TabsHeader
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={activeTab === value ? "text-gray-900" : ""}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
      </div>
      <TabsBody>
        {data.map(({ value, component }) => (
          <TabPanel key={value} value={value}>
            {component}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default FinancesPage;
