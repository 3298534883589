import React, { useEffect, useState } from "react";

export function MapWithDispatchMarkers({ dispatches, API_KEY }) {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  
  const createMarkerIcon = (statusText, callback) => {
    const canvas = document.createElement("canvas");
    canvas.width = 100;
    canvas.height = 100;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = "https://maps.google.com/mapfiles/kml/shapes/cabs.png";
    img.onload = () => {
      ctx.drawImage(img, 0, 0, 50, 50);
      ctx.font = "12px Arial";
      ctx.fillStyle = "red";
      ctx.fillText(statusText, 10, 60);
      const iconUrl = canvas.toDataURL();
      callback(iconUrl); // Call the callback with the created icon URL
    };
  };

  // Function to initialize the Google Map
  const initMap = () => {
    const mapElement = document.getElementById("map");
    if (!mapElement) {
      console.error("Map container not found");
      return;
    }

    try {
      const mapInstance = new window.google.maps.Map(mapElement, {
        zoom: 15,
        center: { lat: -1.2934084171687132, lng: 36.79102873006288 }, // Initial center location
      });

      setMap(mapInstance);
    } catch (error) {
      console.error("Error initializing map:", error);
    }
  };

  // Function to update markers based on dispatches
  const updateMarkers = () => {
    if (!map) return;
    
    // Clear existing markers
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);

    // Add new markers
    dispatches.forEach((dispatch) => {
      const { liveLocation, vehicle, status } = dispatch;
      if (liveLocation) {
        createMarkerIcon(status, (customIconUrl) => {
          const customIcon = {
            url: customIconUrl,
            scaledSize: new window.google.maps.Size(50, 50),
          };

          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(liveLocation.lat), lng: parseFloat(liveLocation.lng) },
            map,
            title: `Vehicle ${vehicle.registrationNumber}`,
            icon: customIcon,
          });

          setMarkers((prevMarkers) => [...prevMarkers, marker]);
        });
      }
    });
  };

  // Load the Google Maps script dynamically
  useEffect(() => {
    const loadScript = () => {
      const existingScript = document.getElementById("maps");

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&avoid=tolls|highways|ferries`;
        script.id = "maps";
        script.async = true;
        script.onload = () => {
          console.log("Google Maps script loaded");
          initMap();
        };
        script.onerror = () => {
          console.error("Failed to load Google Maps script");
        };
        document.head.appendChild(script);
      } else {
        console.log("Google Maps script already loaded");
        initMap();
      }
    };

    if (!window.google) {
      loadScript();
    } else {
      initMap();
    }
  }, [API_KEY]);

  // Update markers when dispatches change
  useEffect(() => {
    updateMarkers();
  }, [dispatches]);

  return <div style={{ width: "100%", height: "600px" }} id="map"></div>;
}

export default MapWithDispatchMarkers;
