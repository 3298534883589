import React, { useEffect, useState } from "react";
import { Card, CardBody, Typography, Spinner } from "@material-tailwind/react";
import TripRoute from "@/widgets/components/pagecomponents/trips/tripRoute";
import TripBookings from "@/widgets/components/pagecomponents/trips/tripBookings";
import TripDispatch from "@/widgets/components/pagecomponents/trips/tripDispatch";
import ManageTrip from "@/widgets/components/pagecomponents/trips/tripManagement";
import { MapProvider } from '@/context/mapContext';

import { fetchTripRoute } from "@/apis/trips";
import sendSentryAlert from "@/utils/sentryUtil";
import GeneralAlert from '@/widgets/alerts/general';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CloudIcon,
  MapPinIcon,
  Cog8ToothIcon,
  ArrowLeftCircleIcon,
  PaperAirplaneIcon
} from "@heroicons/react/24/solid";

export function TripDetails() {
  const [tripObject, setTripObject] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [selectedNav, setSelectedNav] = useState('bookings');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setAlert({ status: false, message: '', type: '' });
    setLoading(true);
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const trip_ = await fetchTripRoute(token, id);
        if (trip_) {
          setTripObject(trip_);
          setLoading(false);
        } else {
          setAlert({
            status: true,
            message: "Unable to fetch trip details at this time.",
            type: 'error',
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendSentryAlert(error);
        setAlert({
          status: true,
          message: 'We are having trouble fetching trip details. Try again.',
          type: 'error',
        });
      }
    };

    fetchData();
  }, []);

  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };
 let content;
  switch (selectedNav) {
    case 'bookings':
      content = <div>Bookings</div>;
      break;
    case 'route':
      content = <div>Route Details</div>;
      break;
    case 'dispatch':
      content = <div>Dispatch</div>;
      break;
    case 'management':
      content = <div>Maintenance Content</div>;
      break;
    default:
      content = null;
  }

  return (
    <div className="m-2">
      <div className="mb-4">
        <div class="p-2 text-left">
          <p class="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
            Trip Code: {tripObject?.routeDetails?.trip_code} 
          </p>
          <p
            class="block font-sans text-base antialiased font-medium leading-relaxed text-transparent bg-clip-text bg-gradient-to-tr from-blue-gray-600 to-blue-gray-400">
            From {tripObject?.routeDetails?.route?.start_point} To {tripObject?.routeDetails?.route?.end_point}
          </p>
        </div>
      </div>
      <div className="mt-12 mb-8 flex flex-row gap-12">
        <div className="flex-shrink-0 w-38 bg-gray-100 py-4">
          <div className="flex flex-col gap-2">
          <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg 'bg-white text-gray-700`}
              onClick={() => navigate('/dashboard/trips')}
            >
              <ArrowLeftCircleIcon className="h-5 w-5 mr-2" />
              Back
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'bookings' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('bookings')}
            >
              <CloudIcon className="h-5 w-5 mr-2" />
              Bookings
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'route' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('route')}
            >
              <MapPinIcon className="h-5 w-5 mr-2" />
              Route
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'dispatch' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('dispatch')}
            >
              <PaperAirplaneIcon className="h-5 w-5 mr-2" />
              Dispatch
            </button>
            <button
              className={`flex items-center justify-start py-2 px-4 rounded-lg ${selectedNav === 'management' ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'}`}
              onClick={() => handleNavClick('management')}
            >
              <Cog8ToothIcon className="h-5 w-5 mr-2" />
              Management
            </button>
          </div>
        </div>
        <div className="flex-1">
          <Card>
            <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
                {loading ? (
                  <Typography>
                    <Spinner />
                  </Typography>
                ) : (
                  alert.status ? (
                    <div className="w-1/2 mx-auto">
                      <GeneralAlert props={{ ...alert, setAlert }} />
                    </div>
                  ) : (
                    <>
                    
                      {selectedNav === 'bookings' && (
                        <div className="p-4">
                            <TripBookings id={id} />
                        </div>
                      )}

                      {selectedNav === 'route' && (
                        <div className="p-4">
                            <TripRoute trip={tripObject} />
                        </div>
                      )}   

                      {selectedNav === 'dispatch' && (
                        <div className="p-4">
                          <TripDispatch id={id}/>
                        </div>
                      )}

                      {selectedNav === 'management' && (
                        <div className="p-4">
                          <ManageTrip trip={tripObject} id={id}/>
                        </div>
                      )}

                    </>
                  )
                )}
              </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TripDetails;
