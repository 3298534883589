import React, { useEffect, useState } from "react";
import {
  Spinner,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import BookingSummary from "@/widgets/charts/bookings-summary"
import TripsStatisticsComponent from "@/widgets/charts/trips-statistics"
import BookingsStatisticsComponent from "@/widgets/charts/bookings-statistics"
import TripSummaryCalendarComponent from "@/widgets/charts/tripSumaryCalenderComponent"
import { fetchSuperadminDashboardData } from "@/apis/dash"

export function SuperAdminUserDashboard() {
  const [loading, setLoading] = useState(false);
  const [dashData, setDashData] = useState({
    "tripsToday": 0,
    "tripsYesterday": 0,
    "tripsLastWeek": {},
    "tripsThisMonth": {},
    "bookingsToday": 0,
    "bookingsYesterday": 0,
    "bookingsLastWeek": {},
    "bookingsThisMonth": {},
    "bookingStatistics":{},
    "driversCount": 0,
    "vehiclesCount": 0,
    "routesCount":0,
    "passengersCount": 0,
    "driverReports": [],
    "bookingsLastYear":[]
  });
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const navigate = useNavigate();

  useEffect(()=>{
    setLoading(true);
    const token = localStorage.getItem('token');
    const fetchData = async () => {
      try{
        const fetchData = await fetchSuperadminDashboardData(token);
        setDashData(fetchData);
      }catch(error){
        setAlert({ status: true, message: 'Error fetching charges.', type: 'error' });
      } finally{
        setLoading(false);
      }
    }

    fetchData();
  },[]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  }

  // if (error || !data) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-gray-100">
  //       <Card className="border border-red-300 shadow-sm">
  //         <CardHeader
  //           floated={false}
  //           shadow={false}
  //           color="red"
  //           className="m-0 p-6"
  //         >
  //           <Typography variant="h6" color="red" className="mb-2">
  //             Error
  //           </Typography>
  //           <Typography
  //             variant="small"
  //             className="flex items-center gap-1 font-normal text-red-600"
  //           >
  //             <strong>500</strong> - Page Data Unavailable
  //           </Typography>
  //         </CardHeader>
  //         <CardBody className="pt-0">
  //           <Typography variant="small" className="text-gray-600">
  //             We encountered an error while trying to fetch the data. Please
  //             try again later.
  //           </Typography>
  //         </CardBody>
  //       </Card>
  //     </div>
  //   );
  // }

  return (
    <div className="mb-4">
        <TripsStatisticsComponent data={dashData || {} } />    
        <BookingsStatisticsComponent data={dashData || {} } />    
        {/* <TripSummaryCalendarComponent /> */}
      {/* <div className="mb-4 grid grid-cols-1 gap-6 xl:grid-cols-1">
      </div> */}
        {/* <BookingSummary bookingsData={dashData.bookingsLastYear} /> */}
      
    </div>
  );
}

export default SuperAdminUserDashboard;
