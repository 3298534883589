import React, { useState, useEffect } from "react";
import { Spinner } from "@material-tailwind/react";
import { ReusableTableComponent } from "@/widgets/components/reusableComponents/tables/reusableTableComponent";
import { fetchFuelChartData } from "@/apis/maintenances";

const ChargeChartComponent = () => {
  const headers = ["Vehicle Capacity", "Cost Per Km", "Fuel Cost", "Status", "Date Created", "Last Updated"];
  const columns = ["vehicle_capacity", "charge_per_km", "fuel_cost", "is_active", "created_at", "updated_at"];

  const [charges, setCharges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });

  useEffect(() => {
    const getVehicles = async () => {
      try {
        const token = localStorage.getItem('token');
        const data = await fetchFuelChartData(token);
        const sortedCharges = data.charges.sort((a, b) => {
          if (a.is_active === b.is_active) {
            return new Date(b.created_at) - new Date(a.created_at);
          }
          return b.is_active - a.is_active;
        });

        setCharges(sortedCharges);
      } catch (error) {
        sendSentryAlert(error);
        setAlert({ status: true, message: 'Error fetching data.', type: 'error' });
      } finally {
        setLoading(false);
      }
    };

    getVehicles();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {alert.status && <GeneralAlert props={{ ...alert, setAlert }} />}
      <ReusableTableComponent
        headers={headers}
        columns={columns}
        data={charges || []}
        sortableColumns={[0, 3]}
        titleColumnIndex={0}
        searchEnabled={true}
        titleClickable={false}
        settingsColumn={false}
        createEntityLink="/dashboard/charges/create"
      />
    </>
  );
};

export default ChargeChartComponent;
