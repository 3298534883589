import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ChargeChartComponent from "@/widgets/components/pagecomponents/finances/chargeChartComponent";
import FuelChargeChartComponent from "@/widgets/components/pagecomponents/finances/fuelChargeChartComponent";
 
export function ChargesComponent() {
  const data = [
    {
      label: "Fuel Chart",
      value: "fuelChart",
      component: <FuelChargeChartComponent />
    },
    {
      label: "Charge Chart",
      value: "chargeChart",
      component: <ChargeChartComponent />
    }
  ];
 
  return (
    <Tabs value="fuelChart" className="w-full">
      <TabsHeader
        className="bg-transparent"
        indicatorProps={{
          className: "bg-gray-900/10 shadow-none !text-gray-900",
        }}
      >
        {data.map(({ label, value }) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
export default ChargesComponent;
