import React, { useState, useEffect } from 'react';
import {
  Card,
  Input,
  Button,
  Typography,
  Select,
  Option,
  Spinner,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
} from "@material-tailwind/react";
import GeneralAlert from '@/widgets/alerts/general';
import { updateOrgFunction, activateDeactivateOrgFunction } from "@/apis/organisations";

export function OrganisationManagement({ formData, id, fetchData}) {
  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState({ ...formData });
  const [alert, setAlert] = useState({ status: false, message: '', type: '' });
  const [activeTab, setActiveTab] = useState("editDetails");
  const token = localStorage.getItem('token');
  const [isActivated, setIsActivated] = useState(formData.status);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrgData({ ...orgData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAlert({ status: false, message: '' });
    try {
      const updateOrganisation = await updateOrgFunction(token, id, orgData);
      if (updateOrganisation) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Organisation updated successfully.',
          type: "success"
        });
        setIsActivated(!formData.status)
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update organisation.',
          type: "error"
        });
      }

    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: "error"
      });
    }
  };

  const handleToggleActivation = async () => {
    try {
      const updateOrganisation = await activateDeactivateOrgFunction(token, formData.id);
      if (updateOrganisation) {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Organisation updated successfully.',
          type: "success"
        });
        setIsActivated(!isActivated);
      } else {
        setLoading(false);
        setAlert({
          status: true,
          message: 'Failed to update organisation.',
          type: "error"
        });
      }

    } catch (error) {
      setLoading(false);
      setAlert({
        status: true,
        message: error.response?.data?.message || 'System currently unable to handle your request. Contact Admin.',
        type: "error"
      });
    }
  };

  return (
    <Card color="transparent" shadow={false}>
      <Tabs value={activeTab} onChange={(value) => setActiveTab(value)}>
        <TabsHeader>
          <Tab value="editDetails">Edit Details</Tab>
          <Tab value="activateDeactivate">Activate/Deactivate</Tab>
        </TabsHeader>
        { alert.status ? (
              <div className="w-1/2 m-6 ">
                <GeneralAlert props={{ ...alert, setAlert }} />
              </div>
            ) : null}
        <TabsBody>         
        <TabPanel value="editDetails">
          <form className="m-4" onSubmit={handleSubmit}>
            <div className="mb-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
              <div className="col-span-1">
                <Typography variant="h6" color="blue-gray" className="mb-3">
                  Name
                </Typography>
                <Input
                  size="lg"
                  name="name"
                  value={orgData.name}
                  onChange={handleInputChange}
                  className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{ className: "before:content-none after:content-none" }}
                />
              </div>
              <div className="col-span-1">
                <Typography variant="h6" color="blue-gray" className="mb-3">
                  Organisation Type
                </Typography>
                <Input
                  size="lg"
                  name="agencyType"
                  value={orgData.agencyType}
                  disabled
                  className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                  labelProps={{ className: "before:content-none after:content-none" }}
                />
              </div>
              <div className="col-span-1">
                <Button className="mt-6" fullWidth type="submit" disabled={loading}>
                  {loading ? <Spinner size="small" /> : 'Edit Organisation'}
                </Button>
              </div>
            </div>
          </form>
        </TabPanel>


          <TabPanel value="activateDeactivate">
            <div className="m-4">
              <Typography variant="body1" color="blue-gray" className="mb-6">
                Organisation currenty {isActivated ? 'Active' : 'Inactive'}. This means no operations can be done on behalf of the organisation.<br />Please make sure to review the organisation details before making any changes.
              </Typography>
              { loading ? (
                <Button>
                  <Spinner />
                </Button>
              ) : (
                <Button color={isActivated ? "red" : "green"} onClick={()=>handleToggleActivation()}>
                  {isActivated ? "Deactivate Organisation" : "Activate Organisation"}
                </Button>
              )}
            </div>
          </TabPanel>
        </TabsBody>
      </Tabs>
    </Card>
  );
}

export default OrganisationManagement;
